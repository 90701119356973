import React, { useState } from 'react';
import '../stylesheets/Info.css';

const Info = ({ item, selected, closeModal, handleSelection }) => {
  const {
    RoomDescription,
    RatePlans: { RatePlan },
  } = item;

  const {
    RatePlanName,
    PrepaidIndicator,
    AvailableQuantity,
    RateSource,
    RatePlanDescription,
    ConvertedRateInfo,
  } = RatePlan[0];

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = (e) => {
    e.stopPropagation();
    setShowDetails(!showDetails);
  };

  const select = (e) => {
    e.stopPropagation();
    handleSelection(item);
    closeModal && closeModal(e);
  }


  return (
    <div onClick={select} className={"col-12 item-info" + (selected ? ' active':' btn btn-light')}>
      <h4>{RatePlanName}</h4>
      <div className="d-flex justify-content-between">
        <p><b>Average Nightly Rate:</b> ${ConvertedRateInfo?.AverageNightlyRate}</p>
      </div>
      <p className="toggle-details" onClick={toggleDetails}>More...</p>

      {showDetails && (
        <>
          <p>{RoomDescription?.Text[0]}</p>
          <p>{RatePlanDescription?.Text}</p>
          <p>Prepaid: {PrepaidIndicator ? 'Yes' : 'No'}</p>
          <p>Available Quantity: {AvailableQuantity}</p>
          <p>Rate Source: {RateSource}</p>
          <p>Start Date: {ConvertedRateInfo?.StartDate}</p>
          <p>End Date: {ConvertedRateInfo?.EndDate}</p>
          <p>Total Before Tax: ${ConvertedRateInfo?.AmountBeforeTax}</p>
          <p>Total After Tax: ${ConvertedRateInfo?.AmountAfterTax}</p>
          <p>Currency: {ConvertedRateInfo?.CurrencyCode}</p>
          <p>Additional Fees Inclusive: {ConvertedRateInfo?.AdditionalFeesInclusive ? 'Yes' : 'No'}</p>
          <p>Tax Inclusive: {ConvertedRateInfo?.TaxInclusive ? 'Yes' : 'No'}</p>
          <p>Tax Amount: {ConvertedRateInfo?.Taxes?.Amount}</p>
          <p>Tax Currency Code: {ConvertedRateInfo?.Taxes?.CurrencyCode}</p>
          <p>Tax Groups:</p>
          <ul>
            {ConvertedRateInfo?.Taxes?.TaxGroups?.TaxGroup.map((tax) => (
              <li key={tax.Code}>{tax.Description}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Info;
