import React from "react";
import {DirectionsCar, HotelRounded} from "@mui/icons-material";

import '../stylesheets/Logo.css';

function Logo ({src, k}) {

  if (!src) {
    switch (k) {
      case 'hotel':
        return <HotelRounded fontSize="large" />;
      case 'auto':
        return <DirectionsCar fontSize="large" />;
      case 'air':
      default:
        return <HotelRounded fontSize="large" />;
    }
  }
  
  return  <img src={src} alt={k + " logo"} className={k + " logo"} />;
}

export default Logo;
