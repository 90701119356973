import React, {useCallback, useEffect, useState} from 'react';
import './App.css';
import Login from './components/general/Login';

import {Route, Routes, useNavigate} from "react-router-dom";
import Hotel from "./components/hotel/Hotel";
import Air from "./components/air/Air";
import Auto from "./components/auto/Auto";
import Review from "./components/review/Review";
import {getSess} from "./utils/dataFetching";
import Header from "./components/general/Header";
import Response from "./components/response/Response";
import Success from "./components/success/Success";

function App() {
  document.title = 'Tangerine Travel Pricing App';

  // const [backgroundClass, setBackgroundClass] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(getSess('company'));
  const pth = () => {
    const p = window.location.pathname.split('/');
    return p.length > 0 ? p[1] : '';
  }

  const [step, setStep] = useState(pth());
  const navigate = useNavigate();

  const handleLoginSuccess = (company) => {
    company.config = JSON.parse(company.config);
    sessionStorage.setItem('company',JSON.stringify(company));
    setIsAuthenticated(company);
  };

  const handleLogout = useCallback((e) => {
    e?.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    setIsAuthenticated(false);
    navigate('/');
    setStep('');
  },[navigate]);

  const canReview = () => {
    const config = isAuthenticated?.config;
    
    if (!config || (config?.air && !getSess('flights')) 
        || (config?.hotel && !getSess('room'))
        || (config?.car && !getSess('vehicle'))) {
      return false;
    }
      
    else if (config) {
      return true;
    }
  }

  // useEffect(() => {
  //   const randomImage = Math.floor(Math.random() * 4) + 1;
  //   setBackgroundClass(`bg-${randomImage}`);
  // }, []);

  useEffect(() => {
    async function fetchToken() {
      try {
        const token = localStorage.getItem("auth_token");
        if (!token) {
          // const API_TOKEN = await getToken();
          // localStorage.setItem("auth_token", API_TOKEN);
        }
      } catch (error) {}
    }

    fetchToken();

    return () => {
      localStorage.removeItem("auth_token");
    };
  }, []);

  useEffect(() => {
    const config = isAuthenticated?.config;
    const currentSeg = pth();

    if (step && step !== currentSeg && currentSeg !== 'next') {
      setStep(currentSeg);
      return;
    }
    if (step && currentSeg === 'success') {
      setStep('success');
      return;
    }
    if (config?.air && (!getSess('flights') || currentSeg === 'air')) {
      navigate('/air');
      setStep('air');
      return;
    }
    if (config?.hotel && (!getSess('room') || currentSeg === 'hotel')) {
      navigate('/hotel');
      setStep('hotel');
      return;
    }
    if (config?.car && (!getSess('vehicle') || currentSeg === 'auto')) {
      navigate('/auto');
      setStep('auto');
      return;
    }
    else if (config) {
      navigate('/review');
      setStep('review');
      return;
    }
    if (/^(air|hotel|auto|review|success)$/.test(currentSeg)) {
      handleLogout();
    }
    
  }, [isAuthenticated, navigate, step, handleLogout]);
  
  return (
    <div className={`col-11 mt-4 pt-4 App ${step}`}>
      <Header handleLogout={handleLogout} isAuthenticated={isAuthenticated} />
      {isAuthenticated ? (
          <Routes>
            <Route path="/hotel" element={<Hotel />} />
            <Route path="/air" element={<Air />} />
            <Route path="/auto" element={<Auto />} />
            <Route path="/success" element={<Success />} />
            {canReview() ? 
            <Route path="/review" element={<Review />} />
              : null }
          </Routes>
      ) : (
        <Routes>
          <Route path="/request/:requestId/:requestChallenge" element={<Response />} />
          <Route path="/" element={<Login onLoginSuccess={handleLoginSuccess} />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
