import React from 'react';
import Result from './SearchResult';
import "../stylesheets/SearchResults.css";

const SearchResults = ({ results, handleItemSelection, handleSearchSubmit }) => {
  const res = results.itineraryGroups?.length && results.itineraryGroups[0].itineraries.length;
  
  const change = (e) => {
    e.stopPropagation();
    handleSearchSubmit(false);
  }

  return (
    <div className="container">
      <h2>
        {res ? 
        <>
          Results ({res})
        </> : <>
          No results.  Modify search and try again: 
        </>}
        <button onClick={change} className="btn btn-info mx-2">Change</button>
      </h2>
      <div className="results">
        {results.itineraryGroups?.map((ig,i) => {

          return <Result key={i}
                         parentIdx={i}
                         parent={ig}
                         handleItemSelection={handleItemSelection} />
        }) || ''}</div>
    </div>
  );
};

export default SearchResults;
