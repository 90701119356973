import React, { useRef } from 'react';
import '../stylesheets/Modal.css';

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose(e);
    }
    e.stopPropagation();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal" onClick={handleOutsideClick}>
      <div className="container modal-header">
        <button className="close-btn" onClick={onClose}>
          <span className="close-icon px-2">Close</span>
        </button>
      </div>
      <div className="container modal-content" ref={modalRef}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
