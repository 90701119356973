import React from 'react';
import Info from "./Info";

import '../stylesheets/Available.css';

const Available = ({ parent, parentIdx, handleSelection }) => {
  const itemKey = 'flight';

  const {
    groupDescription,
    itineraries,
  } = parent;
  const { legDescriptions } = groupDescription;

  const itemList = (itins) => {
    if (itins) {
      return (
        <div className="col-12">
          <h3>Fares:</h3>
          <ul className="item-list">
            {itins.map((itin,i) => (
              <Info key={i} item={itin}
                    parentIdx={parentIdx}
                    legDescriptions={legDescriptions}
                    handleSelection={handleSelection}
              />
            ))}
          </ul>
        </div>);
    }
    else {
      return <div className="error"><b>{itemKey} Info Currently Unavailable:</b> {itins.message}</div>
    }
  }

  return (
    <div className="container available-items">
      {itemList(itineraries)}
    </div>
  );
};

export default Available;
