import axios from "axios";
import { getToken } from "./fetch/userFetching";

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("auth_token");
    if (token) request.headers.authorization = `Bearer ${token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const newToken = await getToken();
        localStorage.setItem("auth_token", newToken);
        return axios(originalRequest);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(error);
  }
);


const setSess = (key, val) => {
  sessionStorage.setItem(key, val ? JSON.stringify(val) : false);
}

const getSess = (key) => {
  const v = sessionStorage.getItem(key);
  return v ? JSON.parse(v) : false;
}

const setLocal = (key, val) => {
  localStorage.setItem(key, JSON.stringify(val));
}

const getLocal = (key) => {
  const v = localStorage.getItem(key);
  return v ? JSON.parse(v) : false;
}

export {
  getSess,
  getLocal,
  setSess,
  setLocal
};
