import React, {useState} from 'react';
import Result from './SearchResult';
import "../stylesheets/SearchResults.css";
import {setSess} from "../../utils/dataFetching";

const SearchResults = ({ results, handleItemSelection, selectedItem, handleSearchSubmit }) => {
  const parentKey = 'hotel';
  const res = results?.HotelAvailInfo?.length;
  const [selectedParent, setSelectedParent] = useState(false);
  const handleParentSelection = (p) => {
    setSelectedParent(p);
    setSess(parentKey,p);
  }

  const change = (e) => {
    e.stopPropagation();
    handleSearchSubmit(false);
  }

  return (
    <div className="container">
      <h2>
        {res ? 
        <>
          Results ({res})
        </> : <>
          No results.  Modify search and try again:  
        </>}
        <button onClick={change} className="btn btn-info mx-4">Change</button>
      </h2>
      <div className="results">
        {results?.HotelAvailInfo?.map((h,i) => (
          <Result key={i} parent={h}
                  selectedItem={selectedItem}
                  selected={h.HotelInfo.HotelCode === selectedParent?.HotelInfo?.HotelCode}
                  handleItemSelection={handleItemSelection}
                  handleParentSelection={handleParentSelection} />
        )) || ''}</div>
    </div>
  );
};

export default SearchResults;
