import React, { useState } from 'react';
import '../stylesheets/SearchResult.css';
import Available from './Available';
import Modal from "../general/Modal";
import ParentLogo from "../general/Logo";

const SearchResult = ({ parent,
                        handleItemSelection,
                        handleParentSelection,
                        selected,
                        selectedItem }) => {
  const parentKey = 'hotel';
  const {
    HotelName,
    Logo,
    Distance,
    UOM,
    SabreRating,
    LocationInfo,
    Amenities,
  } = parent.HotelInfo;
  //const {AverageNightlyRate} = parent.HotelRateInfo.RateInfos.ConvertedRateInfo[0];
  const [showAmenities, setShowAmenities] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleAmenities = (e) => {
    e.stopPropagation();
    setShowAmenities(!showAmenities);
  };

  const openModal = () => {
    setShowModal(true);
    handleParentSelection(parent);
  };

  const closeModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
  };

  return (
    <>
      <div className="result" onClick={openModal}>
        <div className="header">
          <div className="me-3">
            <ParentLogo src={Logo} k={parentKey} />
          </div>
          <div className="details">
            <h3>{HotelName}</h3>
            <p>Rating: {SabreRating}</p>
            <p>Distance: {Distance} {UOM}</p>
{/*             <p>Average Nightly Rate: ${AverageNightlyRate}</p> */}
          </div>
        </div>
        <div className="location">
          <p>{LocationInfo.Address.AddressLine1}</p>
          <p>{LocationInfo.Address.CityName?.value}, {LocationInfo.Address.StateProv?.value} {LocationInfo.Address.PostalCode}</p>
          <p>{LocationInfo.Address.CountryName?.value}</p>
        </div>
        <div className="amenities d-flex flex-wrap flex-column">
          <button className="btn btn-secondary" onClick={toggleAmenities}>
            Show Amenities
          </button>
          <br/>
          <button className="btn btn-primary">
            See Rates
          </button>
          <Modal isOpen={showAmenities} onClose={toggleAmenities}>
            <ul>
              {Amenities.Amenity.map((amenity) => (
                <li key={amenity.Code}>{amenity.Description}</li>
              ))}
            </ul>
          </Modal>
  
        </div>
      </div>
      <Modal isOpen={showModal} onClose={closeModal}>
        <Available parent={parent}
                   selectedItem={selectedItem}
                   closeModal={closeModal}
                   handleSelection={handleItemSelection} />
      </Modal>
    </>
  );
};

export default SearchResult;
