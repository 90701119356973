import React from 'react';
import '../stylesheets/Available.css';
import Info from "./Info";
import ContentCarousel from "../general/ContentCarousel";
import ParentLogo from "../general/Logo";

const Available = ({ parent, closeModal, handleSelection, selectedItem }) => {
  const parentKey = 'auto';
  const itemKey = 'vehicle';

  const { Logo, Name } = parent[0].Vendor;
  const data = parent.filter(i => i.Vendor);

  const itemList = (d) => {
    const data = d.length ? d : false;
    if (data) {
      return (
        <div className="col-12">
          <div className="item-list d-flex flex-wrap justify-content-between">
            {data.map((v,i) => {
              const sel = selectedItem?.VehRentalRate && v.VehRentalRate[0].RateKey === selectedItem?.VehRentalRate[0].RateKey
              return <Info key={i} item={v}
                           closeModal={closeModal}
                           handleSelection={handleSelection}
                           selected={sel} />
            })}
          </div>
        </div>);
    }
    else {
      return <div className="error"><b>{itemKey} Info Currently Unavailable:</b> {d.message}</div>
    }
  }

  const mediaList = (d) => {
    const data = d.results?.HotelContentInfo?.HotelMediaInfo?.MediaItems.MediaItem;
    if (data) {
      return <ContentCarousel mediaList={data} />
    }
  }

  return (
    <div className="auto container mt-0 available-items">
      <div className="info">
        <div className="d-flex mb-3">
          {!Logo ? <h2>{Name}</h2>: null}
          <ParentLogo src={Logo} k={parentKey} />
        </div>
        {mediaList(data)}
      </div>
      {itemList(data)}
    </div>
  );
};

export default Available;
