import React, { useEffect, useState } from "react";
import {getCompany} from "../utils/fetch/companyFetching";
import {getUser} from "../utils/fetch/userFetching";
import {setSess} from "../utils/dataFetching";

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

const AuthProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [company, setCompany] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    async function asyncGetUser() {
      try {
        setIsAuthenticated(true);
        setIsAuthenticating(false);

      } catch (error) {}
      setIsAuthenticating(false);
    }

    asyncGetUser();
  }, []);

  const login = async (emailOrCompanyCode, password) => {
    try {
      let company = {}, user = {};

      if (!password) {
        setSess('emailOrCompanyCode', emailOrCompanyCode);

        company = await getCompany(emailOrCompanyCode);
        setCompany(company);
      }
      else {
        user = await getUser(emailOrCompanyCode, password);
        setUser(user);
      }

      if (company || user) {

        setIsAuthenticated(true);

        return { ...company, ...user };
      }
      else {
        throw new Error("Invalid credentials");
      }
    } catch (error) {
      return { status: "error", error: error?.message ? error.message : "An unexpected error occurred, please try again."};
    }
  };

  const logout = async (email) => {
    try {
      setIsAuthenticated(false);
      setUser(null);

      return { status: "success" };
    } catch (error) {
      return { status: "error", error: error.message };
    }
  };

  const signup = async (email, password) => {
    try {
      //return { status: "success", username: 'placehold-' };
    } catch (error) {
      return { status: "error", error: error.message };
    }
  };

  const authProps = {
    isAuthenticated,
    isAuthenticating,
    company,
    user,
    setAuthStatus: setIsAuthenticated,
    setUser,
    login,
    logout,
    signup,
  };

  return <AuthContext.Provider value={authProps} {...props} />;
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
}

export { AuthContext, AuthProvider, useAuth };
