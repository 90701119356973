import React, {useEffect, useState} from 'react';
import {getLocal, setLocal, setSess} from "../../utils/dataFetching";
import {getFlightAvailability} from "../../utils/fetch/flightFetching";
import {airlineOptions, classOptions, flightTimeOptions} from "../../utils/constants";
import {formattedDate} from "../../utils/helpers";

import {EscalatorWarning, Flight, Person, Place} from "@mui/icons-material";
import {Checkbox, FormControl, FormControlLabel, MenuItem, Select} from "@mui/material";

import '../stylesheets/SearchForm.css';
import {useNavigate} from "react-router-dom";
import AutoGeoComplete from "../general/AutoGeoComplete";

const SearchForm = ({onSearchSubmit}) => {
  const parentKey = 'air';
  const navigate = useNavigate();

  const [formData, setFormData] = useState(getLocal(parentKey+'SearchFormData') || {
    origin: [''],
    destination: [''],
    departDate: [''],
    departTime: ['*'],
    returnDate: '',
    returnTime: '*',
    adults: 1,
    children: 0,
    child: [],
    nonstop: false,
    preferredAirline: airlineOptions[0].value,
    preferredClass: classOptions[0].value,
    excludeBasicEcon: false,
    pageSize: 10,
    rateMin: 0,
    rateMax: 9999,
    ratingMin: 3,
    ratingMax: 5
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [mode , setMode] = useState(getLocal(parentKey+"mode") || "round-trip");

  const [focusedInput, setFocusedInput] = useState('');
  const [inputSuggestValue, setInputSuggestValue] = useState('');

  const handleInputFocus = (e) => {
    setFocusedInput(e.target.name);
    e.stopPropagation();

    if (e.target.value?.length > 2 && e.target.value !== inputSuggestValue) {
        setInputSuggestValue(e.target.value);
    }
  };

  const handleAddFlight = (e) => {
    e.preventDefault();

    formData.origin.push('');
    formData.destination.push('');
    formData.departDate.push('');
    formData.departTime.push('*');

    setFormData({...formData});
  }

  const handleRemoveFlight = (e) => {
    e.preventDefault();
    const num = e.target.dataset.option;
    let { origin, destination, departDate, departTime } = formData;
    origin = origin.toSpliced(num,1);
    destination = destination.toSpliced(num,1);
    departDate = departDate.toSpliced(num,1);
    departTime = departTime.toSpliced(num,1);


    setFormData({...formData,origin, destination, departDate, departTime});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitted) {
      return;
    }
    setSubmitted(true);
    setError('');

    try {
      const fd = {...formData};
      fd.origin = fd.origin.map(o => o.split(' ')[0]);
      fd.destination = fd.destination.map(d => d.split(' ')[0]);
      const searchResults = await getFlightAvailability(fd);

      onSearchSubmit(searchResults);
    } catch (error) {

      setError(error.message);
    }

    setSubmitted(false);
  };

  const handleChange = (e) => {
    if (typeof e.target.value._isValid != 'undefined' && !e.target.value._isValid) {
      return;
    }
    const v = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let n = e.target.name;
    let nnum;
    let val = e.target.type !== 'checkbox' && !isNaN(v) && !/time/i.test(n) && v ? Number(v) : v;

    if (/_/.test(n)) {
      nnum = Number(n.replace(/.*_/g,''));
      n = n.replace(/_.*/g,'');
      formData[n][nnum] = val;
      val = formData[n];
    }

    if (n === 'children') {
      if (val > formData.child.length) {
        formData.child.push([1]);
      }
      else if (formData.child.length > val) {
        formData.child = formData.child.slice(0,val);
      }
    }

    if (/origin|destination/.test(n)) {
      if (e.currentTarget) {
        setInputSuggestValue(e.target.value);
      }
    }

    setFormData({
      ...formData,
      [n]: val
    });
  };

  const handleSkip = (e) => {
    e.stopPropagation();
    setSess('flights','skipped');
    navigate('/next');
  }

  const setSearchMode = (m) => {
    if (mode === 'multi-city' && m !== 'multi-city') {
      let { origin, destination, departDate, departTime } = formData;
      origin = [origin[0]];
      destination = [destination[0]];
      departDate = [departDate[0]];
      departTime = [departTime[0]];


      setFormData({...formData,origin, destination, departDate, departTime});
    }

    if (m !== 'round-trip' && formData.returnDate) {
      setFormData({...formData,returnDate:''});
    }

    setMode(m);
    setLocal(parentKey+"mode",m);
  }

  const btnClass = (b) => {
    return b === mode ? 'warning' : 'dark';
  }

  const childAge = () => {
    const ret = [];

    for (let index = 0; index < formData.children; index++) {
      if (!formData.child[index]) {
        continue;
      }
      ret.push(<div className="col-2 pe-3">
        <label>Child {index + 1} Age</label>
        <input type="number"
               className="form-control"
               id={"child_"+index}
               name={"child_"+index}
               min={1}
               max={17}
               step={1}
               value={formData.child[index]}
               onChange={handleChange}
               required />
      </div>);
    }

    return ret;
  }

  // Save form data to localStorage on change
  useEffect(() => {
    setLocal(parentKey+'SearchFormData', formData);
  }, [formData]);

  return (
    <div className={"container search-form "+mode} onClick={handleInputFocus}>
      <h2><Flight fontSize="large" /> Search <Flight fontSize="large" /></h2>
      <button onClick={handleSkip} type="submit" className="m-3 btn btn-info skip">
        Skip
      </button>
      <div className="row">
        <div className="form-group">
          <button className={"btn me-3 btn-" + btnClass('round-trip')} onClick={(e) => setSearchMode('round-trip')}>Round Trip</button>
          <button className={"btn me-3 btn-" + btnClass('one-way')} onClick={(e) => setSearchMode('one-way')}>One-Way</button>
          <button className={"d-none btn btn-" + btnClass('multi-city')} onClick={(e) => setSearchMode('multi-city')}>Multi-City</button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="row">
        {formData.origin.map((o,i) => {
          if (i > 0 && mode !== 'multi-city') {
            return null;
          }
          return (<div key={i} className="col-12 d-flex flex-wrap">
              <div className="form-group ps-0 col-6">
                <div className="input-group">
                  <span className="input-group-text"><Place fontSize="large" /></span>
                  <input
                    type="text"
                    className="form-control"
                    id={"origin_"+i}
                    name={"origin_"+i}
                    value={formData.origin[i].toUpperCase()}
                    onChange={handleChange}
                    placeholder="From City or Airport"
                    onFocus={handleInputFocus}
                    autoComplete="off"
                    required
                  />
                  <AutoGeoComplete name={"origin_"+i}
                                value={formData.origin[i]}
                                type={'AIR'}
                                handleChange={handleChange}
                                inputSuggestValue={inputSuggestValue}
                                focusedInput={focusedInput}
                                setFocusedInput={setFocusedInput} />
                </div>

              </div>

              <div className="form-group pe-0 col-6">
                <div className="input-group">
                  <span className="input-group-text"><Place fontSize="large" /></span>
                  <input
                    type="text"
                    className="form-control"
                    id={"destination_"+i}
                    name={"destination_"+i}
                    value={formData.destination[i].toUpperCase()}
                    onChange={handleChange}
                    onFocus={handleInputFocus}
                    placeholder="To City or Airport"
                    autoComplete="off"
                    required
                  />
                  <AutoGeoComplete name={"destination_"+i}
                                value={formData.destination[i]}
                                type={'AIR'}
                                handleChange={handleChange}
                                inputSuggestValue={inputSuggestValue}
                                focusedInput={focusedInput}
                                setFocusedInput={setFocusedInput} />
                </div>
              </div>

              <div className="form-group ps-0 col-6">
                <div className="col-6 d-flex flex-nowrap align-items-center">
                  <div className="input-group">
                    <span className="input-group-text">Depart</span>
                    <input
                      type="date"
                      className="form-control"
                      id={"departDate_"+i}
                      name={"departDate_"+i}
                      value={formData.departDate[i]}
                      onChange={handleChange}
                      min={i > 0 ? formData.departDate[i-1] : formattedDate()}
                      required
                    />
                  </div>
                </div>
                <div className="col-5 d-flex">
                  <FormControl fullWidth>
                    <Select id={"departTime_"+i}
                            name={"departTime_"+i}
                            value={formData.departTime[i]}
                            onChange={handleChange}>
                      {flightTimeOptions.map((o,i) => {
                        return <MenuItem key={i} value={o.value}>{o.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {i > 0 ? (
                  <div className="form-group pe-0 col-12">
                    <button className="btn me-3 btn-danger"
                            onClick={handleRemoveFlight}
                            data-option={i}>Remove Flight</button>
                  </div> ) :
                mode === 'round-trip' ? (
                  <div className="form-group col-6">
                    <div className="col-6 d-flex flex-nowrap align-items-center">
                      <div className="input-group">
                        <span className="input-group-text">Return</span>
                        <input
                          type="date"
                          className="form-control"
                          id="returnDate"
                          name="returnDate"
                          value={formData.returnDate}
                          onChange={handleChange}
                          min={formData.departDate[0]}
                          required
                        />
                      </div>

                    </div>
                    <div className="col-5 d-flex">
                      <FormControl fullWidth>
                        <Select id="returnTime" name="returnTime"
                                value={formData.returnTime}
                                onChange={handleChange}>
                          {flightTimeOptions.map((o,i) => {
                            return <MenuItem key={i} value={o.value}>{o.name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>) : null }
            </div>
          )})}

        {mode === 'multi-city' ? (
          <div className="form-group col-12">
            {formData.origin[formData.origin.length - 1] &&
            formData.destination[formData.destination.length - 1] &&
            formData.departDate[formData.departDate.length - 1] ?
              <button className="btn me-3 btn-secondary" onClick={handleAddFlight}>Add Flight</button>
              :
              <button className="btn me-3 btn-light" onClick={(e) => e.preventDefault()}>(From, To, Depart Date required to add more)</button>
            }
          </div>
        ) : null}

        <div className="d-none form-group col-6 justify-content-between">
          <div className="col-5">
            <div className="input-group">
              <span className="input-group-text"><Person fontSize="large" /></span>
              <input
                type="number"
                className="form-control"
                id="adults"
                name="adults"
                min={1}
                step={1}
                value={formData.adults}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-5">
            <div className="input-group">
              <span className="input-group-text"><EscalatorWarning fontSize="large" /></span>
              <input
                type="number"
                className="form-control"
                id="children"
                name="children"
                min={0}
                max={6}
                step={1}
                value={formData.children}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>
        {formData.children > 0 ? (
          <div className="form-group col-12 justify-content-start">
            {childAge()}
          </div>) : null }
        <div className="form-group col-12">
          <FormControlLabel label="Non-stop Flight"
                            control={<Checkbox name="nonstop"
                                               checked={formData.nonstop}
                                               onChange={handleChange}
                            />}
          ></FormControlLabel>
        </div>
        <div className="form-group col-6">
          <div className="col-5 d-flex">
            <FormControl fullWidth>
              <Select id="preferredAirline" name="preferredAirline"
                      value={formData.preferredAirline}
                      onChange={handleChange}>
                {airlineOptions.map((o,i) => {
                  return <MenuItem key={i} value={o.value}>{o.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
          <div className="col-5 d-flex">
            <FormControl fullWidth>
              <Select id="preferredClass" name="preferredClass"
                      value={formData.preferredClass}
                      onChange={handleChange}>
                {classOptions.map((o,i) => {
                  return <MenuItem key={i} value={o.value}>{o.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="form-group col-12">
          <FormControlLabel label="Exclude Basic Economy Fares"
                            control={<Checkbox name="excludeBasicEcon"
                                               checked={formData.excludeBasicEcon}
                                               onChange={handleChange}
                            />}
          ></FormControlLabel>
        </div>
        <div className="form-group col-12">
          <button disabled={submitted ? true : false} 
            type="submit" 
            className={`btn btn-${submitted ? 'secondary':'primary'}`}>
            {submitted ? 'Loading...' : 'Search'}
          </button>
        </div>

      </form>
      {error && (<div className="form-group error">{error}</div> )}
    </div>
  );
};

export default SearchForm;
