import axios from "axios";

const getAutoGeoCompleteSuggestions = async (query, type)=> {
  const q = query.trim();
  const t = type.trim();
  try {
    if (!q || q.length < 3 || !t || t.length < 3) {
      throw new Error('Query & Type must be at least 3 characters long.');
    }
    const response = await axios.get(`${process.env.REACT_APP_API}/autocomplete/geo/${t}/${q}`);

    const data = await response.data;
    return data.results;
  } catch (error) {
    throw new Error(`An error occurred while searching for auto complete suggestions for ${q}`);
  }
}

export { getAutoGeoCompleteSuggestions }
