import '../stylesheets/Login.css';
import React, { useState } from 'react';
import {useAuth} from "../../context/AuthContext";

const Login = ({ onLoginSuccess }) => {
  const [companyCode, setCompanyCode] = useState('');
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const {login} = useAuth();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setSubmitted(true);
    
    try {
      let loginResult = await login(companyCode);

      if (loginResult.success) {
        onLoginSuccess(loginResult.company);
      }
      else if (loginResult.status === 'error') {
        setError(loginResult.error);
      }
      else {
        setError(loginResult && !loginResult.success ? loginResult.message : "An unexpected error occurred.");
      }
    } catch (e) {
      setError(e.message);
    }
    
    setSubmitted(false);
  };

  return (
    <div className="Login-container">
      <h2>Login</h2>
      <form onSubmit={handleFormSubmit}>
        <div className="form-group">
          <input
            type="text"
            id="companyCode"
            className="form-control"
            value={companyCode}
            onChange={(e) => setCompanyCode(e.target.value)}
            required
            placeholder="Enter company code"
          />
          <button disabled={submitted ? true : false} type="submit" className={`btn btn-${submitted ? 'secondary':'primary'} ms-3`}>{submitted ? 'Loading...' : 'Submit'}</button>
        </div>
      </form>
      {error && (<div className="form-group error">{error}</div> )}
    </div>
  );
};

export default Login;
