import axios from "axios";

const getHotelAvailability = async (formData)=> {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API}/hotel/search`, formData);

    const data = await response.data;
    return data.results;
  } catch (error) {
    throw new Error('An error occurred while searching for hotels');
  }
}

const getHotelRooms = async (key)=> {
  try {
    const body = JSON.parse(localStorage.getItem('hotelSearchFormData'));

    const response = await axios.post(`${process.env.REACT_APP_API}/hotel/rooms`, {...body, HotelCode: key.queryKey[1], CodeContext: key.queryKey[2]});

    const data = await response.data;
    return data.results;
  } catch (error) {
    throw new Error('An error occurred while searching for hotels');
  }
}

export { getHotelAvailability , getHotelRooms }
