import React from "react";
import {Link} from "react-router-dom";
import {getSess,setSess} from "../../utils/dataFetching";

function Header ({isAuthenticated, handleLogout}) {
  const companyConfig = isAuthenticated?.config;
  const roomSelection = getSess('room');
  const flightSelection = getSess('flights');
  const vehicleSelection = getSess('vehicle');
  const pth = window.location.pathname.replace(/\W+/g,'');
  
  const buttonBar = () => {
    const handleButtonClick = (e,k) => {
      if (getSess(k) === 'skipped') {
        setSess(k,false);        
      }
    }
    const status = (k,s) => {
      return (k === pth ? 'warning' : s ? 'success' : '')
    }
    let canReview = ((companyConfig?.air && flightSelection) 
         || !companyConfig?.air)
       && ((companyConfig?.hotel && roomSelection) 
         || !companyConfig?.hotel)
       && ((companyConfig?.car && vehicleSelection) 
         || !companyConfig?.car);
    
    return <>
      {companyConfig?.air && status('air',flightSelection) && (<a className={"btn btn-" + status('air',flightSelection)} href="/air" onClick={e => handleButtonClick(e,'flights')}>Air</a> )}
      {companyConfig?.hotel && (status('hotel',roomSelection) || flightSelection) && (<a className={"btn btn-" + (status('hotel',roomSelection) || 'dark')} href="/hotel" onClick={e => handleButtonClick(e,'room')}>Hotel</a> )}
      {companyConfig?.car && (status('auto',vehicleSelection) || roomSelection) && (<a className={"btn btn-" + (status('auto',vehicleSelection) || 'dark')} href="/auto" onClick={e => handleButtonClick(e,'vehicle')}>Car</a> )}
      {isAuthenticated && status('review',canReview) && (<Link className={"btn btn-" + status('review',canReview)} to="/review">Review</Link> )}
      {isAuthenticated && (<button className="btn btn-dark" onClick={handleLogout}>Logout {isAuthenticated.name || isAuthenticated.code}</button> )}
    </>
  }

  return <>
    <div className="app-title"><h1>Tangerine Travel Pricing App</h1></div>
    <div className="header">{buttonBar()}</div>
    </>;
}

export default Header;
