import React, { useState } from 'react';
import '../stylesheets/ContentCarousel.css';

const ContentCarousel = ({ mediaList }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePreviousSlide = () => {
    const p = currentSlide - 1;

    setCurrentSlide(p < 0 ? mediaList.length - 1 : p);
  };

  const handleNextSlide = () => {
    const n = currentSlide + 1;
    setCurrentSlide(n > mediaList.length - 1 ? 0 : n);
  };

  return (
    <div className="media-carousel">
      <div className="carousel-container">
        <button className="prev-slide-btn" onClick={handlePreviousSlide}>
          &lt;
        </button>
        <div className="carousel-items">
          {mediaList.map((media, index) => (
            <MediaItem
              key={index}
              media={media}
              isActive={index === currentSlide}
            />
          ))}
        </div>
        <button className="next-slide-btn" onClick={handleNextSlide}>
          &gt;
        </button>
      </div>
    </div>
  );
};

const MediaItem = ({ media, isActive }) => {
  const { Category, ImageItems } = media;
  const { Description } = Category;
  const { Url } = ImageItems.Image[0];

  return (
    <div className={`media-item ${isActive ? 'active' : ''}`}>
      <img src={Url} alt={Description?.Text[0]?.value} />
      <div className="media-description">
        <p>{Description?.Text[0]?.value}</p>
      </div>
    </div>
  );
};

export default ContentCarousel;
