import React from 'react';
import '../stylesheets/Available.css';
import Info from "./Info";
import {useGetRooms} from "../../utils/hooks/hotelHooks";
import ContentCarousel from "../general/ContentCarousel";
import ParentLogo from "../general/Logo";

const Available = ({ parent, closeModal, handleSelection, selectedItem }) => {
  const parentKey = 'hotel';
  const itemKey = 'room';

  const { HotelInfo } = parent;
  const {isLoading, isIdle, data} = useGetRooms(HotelInfo);

  if (isLoading || isIdle) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data and no error returned, check Hotel Code <b>{HotelInfo.HotelCode}</b> for <b>{HotelInfo.HotelName}</b></div>
  }

  const itemList = (d) => {
    const data = (d.HotelRateInfo && d.HotelRateInfo[0]?.Rooms?.Room) || false;
    if (data) {
      return (
        <div className="col-7">
          <h3>Available {itemKey}s</h3>
          <ul className="item-list">
            {data.map((r,i) => (
              <Info key={i} item={r}
                    closeModal={closeModal}
                    handleSelection={handleSelection}
                    selected={r.RatePlans?.RatePlan[0].RateKey === selectedItem?.RatePlans?.RatePlan[0].RateKey} />
            ))}
          </ul>
        </div>);
    }
    else {
      return <div className="error"><b>We’re sorry, there are no room details available for this hotel. Please try again later or check another hotel.</b></div>
    }
  }

  const mediaList = (d) => {
    const data = d.HotelContentInfo && d.HotelContentInfo?.HotelMediaInfo?.MediaItems.MediaItem;
    if (data) {
      return <ContentCarousel mediaList={data} />
    }
  }

  return (
    <div className="container available-items d-flex justify-content-between">
      <div className="info d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between">
          <div className="col-8">
            <h2>{HotelInfo.HotelName}</h2>
          </div>
          <div className="col-3 me-3">
            <ParentLogo src={HotelInfo.Logo} k={parentKey} />
          </div>
        </div>
        {mediaList(data)}
      </div>
      {itemList(data)}
    </div>
  );
};

export default Available;
