import axios from "axios";
import {getSess} from "../dataFetching";

const getUser = async (email, password) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API}/approver/`,
    {
      email,
      password
    }
  );

  if (typeof data === 'string') {
    throw new Error(data);
  }

  return data;
};

const getToken = async () => {
  const companyCode = getSess('emailOrCompanyCode');
  const { data } = await axios.get(`${process.env.REACT_APP_API}/getToken/${companyCode}`);
  return data;
};

export {
  getUser,
  getToken
}
