
exports.flightTimeOptions = [
  {value:"*",name:"Anytime"},
  {value:"00000600",name:"Midnight to 6am"},
  {value:"06001200",name:"6am to 12pm"},
  {value:"12001800",name:"12pm to 6pm"},
  {value:"18000000",name:"6pm to Midnight"},
];

exports.airlineOptions = [
  {value:"*",name:"No Airline Preference"},
  {value:"EI",name:"Aer Lingus"},
  {value:"AM",name:"Aeromexico"},
  {value:"AB",name:"Air Berlin"},
  {value:"AC",name:"Air Canada"},
  {value:"CA",name:"Air China"},
  {value:"AF",name:"Air France"},
  {value:"AI",name:"Air India"},
  {value:"NZ",name:"Air New Zealand"},
  {value:"AS",name:"Alaska Airlines"},
  {value:"NH",name:"All Nippon"},
  {value:"AA",name:"American"},
  {value:"OZ",name:"Asiana Airlines"},
  {value:"OS",name:"Austrian Airline"},
  {value:"AV",name:"Avianca"},
  {value:"BA",name:"British Airways"},
  {value:"CX",name:"Cathay Pacific"},
  {value:"MU",name:"China Eastern"},
  {value:"CZ",name:"China Southern"},
  {value:"CM",name:"Copa Airlines"},
  {value:"7H",name:"Corvus Airlines"},
  {value:"DL",name:"Delta"},
  {value:"LY",name:"El Al Israel"},
  {value:"EK",name:"Emirates"},
  {value:"ET",name:"Ethiopian Air"},
  {value:"EY",name:"Etihad Airways"},
  {value:"BR",name:"EVA Airways"},
  {value:"FJ",name:"Fiji Airways"},
  {value:"HU",name:"Hainan Airlines"},
  {value:"HA",name:"Hawaiian"},
  {value:"IB",name:"Iberia"},
  {value:"FI",name:"Icelandair"},
  {value:"JL",name:"Japan Airlines"},
  {value:"B6",name:"JetBlue Airways"},
  {value:"KQ",name:"Kenya Airways"},
  {value:"KE",name:"Korean Air lines"},
  {value:"LA",name:"LAN Airlines"},
  {value:"LH",name:"Lufthansa"},
  {value:"QF",name:"Qantas Airways"},
  {value:"QR",name:"Qatar Airways"},
  {value:"SK",name:"SAS"},
  {value:"SQ",name:"Singapore Airl"},
  {value:"WN",name:"Southwest"},
  {value:"LX",name:"Swiss"},
  {value:"JJ",name:"TAM Linhas Aerea"},
  {value:"TG",name:"Thai Intl"},
  {value:"TK",name:"Turkish Airlines"},
  {value:"UA",name:"United"},
  {value:"VX",name:"Virgin America"},
  {value:"VS",name:"Virgin Atlantic"},
  {value:"WS",name:"WestJet"},
  {value:"",name:"---------"},
  {value:"9B",name:"AccesRail"},
  {value:"JP",name:"Adria Airways"},
  {value:"A3",name:"Aegean Air"},
  {value:"EI",name:"Aer Lingus"},
  {value:"SU",name:"Aeroflot"},
  {value:"2K",name:"Aerogal"},
  {value:"AR",name:"Aerolineas Argen"},
  {value:"VW",name:"Aeromar"},
  {value:"AM",name:"Aeromexico"},
  {value:"8U",name:"Afriqiyah"},
  {value:"ZI",name:"Aigle Azur"},
  {value:"AH",name:"Air Algerie"},
  {value:"3S",name:"Air Antilles"},
  {value:"KC",name:"Air Astana"},
  {value:"UU",name:"Air Austral"},
  {value:"BT",name:"Air Baltic"},
  {value:"AB",name:"Air Berlin"},
  {value:"BP",name:"Air Botswana"},
  {value:"2J",name:"Air Burkina"},
  {value:"SB",name:"Air Calin"},
  {value:"AC",name:"Air Canada"},
  {value:"TX",name:"Air Caraibes"},
  {value:"CA",name:"Air China"},
  {value:"3E",name:"Air Choice"},
  {value:"XK",name:"Air Corsica"},
  {value:"YN",name:"Air Creebec"},
  {value:"EN",name:"Air Dolomiti"},
  {value:"UX",name:"Air Europa"},
  {value:"AF",name:"Air France"},
  {value:"GL",name:"Air Greenland"},
  {value:"NY",name:"Air Iceland"},
  {value:"AI",name:"Air India"},
  {value:"3H",name:"Air Inuit"},
  {value:"L4",name:"Air Liaison"},
  {value:"NX",name:"Air Macau"},
  {value:"MD",name:"Air Madagascar"},
  {value:"KM",name:"Air Malta"},
  {value:"MK",name:"Air Mauritius"},
  {value:"9U",name:"Air Moldova"},
  {value:"SW",name:"Air Namibia"},
  {value:"NZ",name:"Air New Zealand"},
  {value:"PX",name:"Air Niugini"},
  {value:"4N",name:"Air North"},
  {value:"TP",name:"Air Portugal"},
  {value:"PJ",name:"Air Saint-Pierre"},
  {value:"JU",name:"Air Serbia"},
  {value:"HM",name:"Air Seychelles"},
  {value:"4D",name:"Air Sinai"},
  {value:"VT",name:"Air Tahiti"},
  {value:"TN",name:"Air Tahiti Nui"},
  {value:"TC",name:"Air Tanzania"},
  {value:"TS",name:"Air Transat"},
  {value:"NF",name:"Air Vanuatu"},
  {value:"ZW",name:"Air Wisconsin"},
  {value:"TL",name:"AirNorth"},
  {value:"AS",name:"Alaska Airlines"},
  {value:"J5",name:"Alaska Seaplane"},
  {value:"AZ",name:"Alitalia"},
  {value:"NH",name:"All Nippon"},
  {value:"AA",name:"American"},
  {value:"DT",name:"Angola Airlines"},
  {value:"W3",name:"Arik Air"},
  {value:"IZ",name:"Arkia Israeli"},
  {value:"MV",name:"Armenian Intl"},
  {value:"R7",name:"Aserca Air"},
  {value:"OZ",name:"Asiana Airlines"},
  {value:"RC",name:"Atlantic Airways"},
  {value:"KK",name:"AtlasGlobal"},
  {value:"GR",name:"Aurigny Air Svc"},
  {value:"AU",name:"Austral Lineas"},
  {value:"OS",name:"Austrian Airline"},
  {value:"AV",name:"Avianca"},
  {value:"J2",name:"Azerbaijan Airl"},
  {value:"AD",name:"Azul Linhas"},
  {value:"JA",name:"B and H Airlines"},
  {value:"UP",name:"Bahamasair"},
  {value:"PG",name:"Bangkok Airways"},
  {value:"JV",name:"Bearskin Airline"},
  {value:"4T",name:"Belair Airlines"},
  {value:"B2",name:"Belavia"},
  {value:"8E",name:"Bering Air"},
  {value:"BG",name:"Biman Bangladesh"},
  {value:"NT",name:"Binter Canarias"},
  {value:"BV",name:"Blue Panorama"},
  {value:"4B",name:"Boutique Air"},
  {value:"1X",name:"Branson Air"},
  {value:"BA",name:"British Airways"},
  {value:"SN",name:"Brussels Airline"},
  {value:"FB",name:"Bulgaria Air"},
  {value:"XM",name:"CAI First SPA"},
  {value:"MO",name:"Calm Air Intl"},
  {value:"5T",name:"Canadian North"},
  {value:"9K",name:"Cape Air"},
  {value:"BW",name:"Caribbean Air"},
  {value:"V3",name:"Carpatair"},
  {value:"CX",name:"Cathay Pacific"},
  {value:"KX",name:"Cayman Airways"},
  {value:"9M",name:"Central Mtn Air"},
  {value:"CI",name:"China Airlines"},
  {value:"MU",name:"China Eastern"},
  {value:"CZ",name:"China Southern"},
  {value:"MN",name:"Comair"},
  {value:"DE",name:"Condor"},
  {value:"CO",name:"Continental"},
  {value:"CM",name:"Copa Airlines"},
  {value:"SS",name:"Corsair"},
  {value:"7H",name:"Corvus Airlines"},
  {value:"OU",name:"Croatia Airlines"},
  {value:"OK",name:"Czech Airlines"},
  {value:"N2",name:"Dagestan Air"},
  {value:"DL",name:"Delta"},
  {value:"Z6",name:"Dniproavia"},
  {value:"KA",name:"Dragonair"},
  {value:"T3",name:"Eastern Airways"},
  {value:"U2",name:"Easyjet"},
  {value:"MS",name:"Egyptair"},
  {value:"LY",name:"El Al Israel"},
  {value:"EK",name:"Emirates"},
  {value:"B8",name:"Eritrean Air"},
  {value:"OV",name:"Estonian Air"},
  {value:"ET",name:"Ethiopian Air"},
  {value:"EY",name:"Etihad Airways"},
  {value:"9F",name:"Eurostar"},
  {value:"EW",name:"Eurowings"},
  {value:"BR",name:"EVA Airways"},
  {value:"7V",name:"Federal Airlines"},
  {value:"FJ",name:"Fiji Airways"},
  {value:"AY",name:"Finnair"},
  {value:"7F",name:"First Air"},
  {value:"W2",name:"FlexFlight ApS"},
  {value:"BE",name:"Flybe"},
  {value:"FZ",name:"flydubai"},
  {value:"F9",name:"Frontier"},
  {value:"JH",name:"Fuji Dream"},
  {value:"GA",name:"Garuda"},
  {value:"A9",name:"Georgian Airways"},
  {value:"4U",name:"Germanwings"},
  {value:"ZK",name:"Great Lakes"},
  {value:"GF",name:"Gulf Air"},
  {value:"HR",name:"Hahn Air Lines"},
  {value:"H1",name:"Hahn Air Systems"},
  {value:"HU",name:"Hainan Airlines"},
  {value:"HA",name:"Hawaiian"},
  {value:"BH",name:"Hawkair"},
  {value:"YO",name:"Heli-Air Monaco"},
  {value:"2L",name:"Helvetic Airways"},
  {value:"UD",name:"Hex Air"},
  {value:"HX",name:"Hong Kong Air"},
  {value:"UO",name:"Hong Kong Expres"},
  {value:"A5",name:"HOP"},
  {value:"DB",name:"HOP Brit Air"},
  {value:"YS",name:"HOP Regional"},
  {value:"QX",name:"Horizon Air"},
  {value:"IB",name:"Iberia"},
  {value:"FI",name:"Icelandair"},
  {value:"7I",name:"Insel Air"},
  {value:"8I",name:"InselAir"},
  {value:"D6",name:"Interair"},
  {value:"3L",name:"Intersky"},
  {value:"IR",name:"Iran Air"},
  {value:"WP",name:"Island Air Hawai"},
  {value:"6H",name:"Israir"},
  {value:"JL",name:"Japan Airlines"},
  {value:"NU",name:"Japan Trans Air"},
  {value:"J9",name:"Jazeera Airways"},
  {value:"7C",name:"JeJu Air"},
  {value:"TB",name:"Jet Airfly"},
  {value:"9W",name:"Jet Airways"},
  {value:"JF",name:"Jet Asia Airways"},
  {value:"LS",name:"Jet2.com"},
  {value:"B6",name:"JetBlue Airways"},
  {value:"S2",name:"JetKonnect"},
  {value:"JQ",name:"Jetstar Airways"},
  {value:"3K",name:"Jetstar Asia Air"},
  {value:"GK",name:"Jetstar Japan"},
  {value:"BL",name:"Jetstar Pacific"},
  {value:"R5",name:"Jordan Aviation"},
  {value:"RQ",name:"Kam Air"},
  {value:"FK",name:"Keewatin Air"},
  {value:"KQ",name:"Kenya Airways"},
  {value:"KL",name:"KLM"},
  {value:"WA",name:"KLM Cityhopper"},
  {value:"KE",name:"Korean Air lines"},
  {value:"KU",name:"Kuwait Airways"},
  {value:"B0",name:"La Compagnie"},
  {value:"WJ",name:"Labrador"},
  {value:"LR",name:"LACSA"},
  {value:"TM",name:"LAM"},
  {value:"LA",name:"LAN Airlines"},
  {value:"4M",name:"Lan Argentina"},
  {value:"UC",name:"Lan Chile Cargo"},
  {value:"4C",name:"LAN Colombia"},
  {value:"XL",name:"Lan Ecuador"},
  {value:"LP",name:"Lan Peru"},
  {value:"QV",name:"Lao Airlines"},
  {value:"LI",name:"Liat Ltd"},
  {value:"LO",name:"LOT Polish"},
  {value:"LH",name:"Lufthansa"},
  {value:"LG",name:"Luxair"},
  {value:"W5",name:"Mahan Air"},
  {value:"MH",name:"Malaysia"},
  {value:"OD",name:"Malindo Airways"},
  {value:"TF",name:"Malmo Aviation"},
  {value:"JE",name:"Mango"},
  {value:"IG",name:"Meridiana Fly"},
  {value:"ME",name:"Middle East"},
  {value:"MW",name:"Mokulele Airline"},
  {value:"2M",name:"Moldavian"},
  {value:"OM",name:"Mongolian"},
  {value:"YM",name:"Montenegro"},
  {value:"M9",name:"Motor Sich"},
  {value:"8M",name:"Myanmar Airways"},
  {value:"RA",name:"Nepal Airlines"},
  {value:"HG",name:"Niki Luftfahrt"},
  {value:"DD",name:"Nok Air"},
  {value:"5N",name:"Nordavia Airline"},
  {value:"J3",name:"Northwestern Air"},
  {value:"D8",name:"Norwegian"},
  {value:"DY",name:"Norwegian Air"},
  {value:"OA",name:"Olympic Air"},
  {value:"WY",name:"Oman Air"},
  {value:"J1",name:"One Jet"},
  {value:"R2",name:"Orenair"},
  {value:"8P",name:"Pacific Coastal"},
  {value:"LW",name:"Pacific Wings"},
  {value:"CG",name:"Papua New Guinea"},
  {value:"PC",name:"Pegasus Airlines"},
  {value:"KS",name:"PenAir"},
  {value:"PR",name:"Philippine"},
  {value:"PD",name:"Porter Airlines"},
  {value:"PW",name:"Precision Air"},
  {value:"P0",name:"Proflight"},
  {value:"PB",name:"Provincial Airl"},
  {value:"P1",name:"Public Charters"},
  {value:"QF",name:"Qantas Airways"},
  {value:"QR",name:"Qatar Airways"},
  {value:"ZL",name:"Regional Express"},
  {value:"AT",name:"Royal Air Maroc"},
  {value:"BI",name:"Royal Brunei"},
  {value:"RL",name:"Royal Falcon"},
  {value:"RJ",name:"Royal Jordanian"},
  {value:"WB",name:"Rwandair Express"},
  {value:"4Q",name:"Safi Airways"},
  {value:"S3",name:"Santa Barbara"},
  {value:"SK",name:"SAS"},
  {value:"SP",name:"SATA Air Acores"},
  {value:"S4",name:"SATA Intl"},
  {value:"SV",name:"Saudi Arabian"},
  {value:"K5",name:"Seaport Airlines"},
  {value:"DN",name:"Senegal Airlines"},
  {value:"SC",name:"Shandong"},
  {value:"FM",name:"Shanghai"},
  {value:"ZH",name:"Shenzhen Airline"},
  {value:"S7",name:"Siberia Air"},
  {value:"3U",name:"Sichuan Airlines"},
  {value:"MI",name:"SilkAir"},
  {value:"3M",name:"Silver Airways C"},
  {value:"SQ",name:"Singapore Airl"},
  {value:"SX",name:"Sky"},
  {value:"H2",name:"Sky Airline"},
  {value:"OO",name:"Skywest"},
  {value:"QS",name:"Smartwings"},
  {value:"IE",name:"Solomon Airlines"},
  {value:"SA",name:"South African"},
  {value:"9X",name:"Southern Airways"},
  {value:"WN",name:"Southwest"},
  {value:"SG",name:"Spice Jet"},
  {value:"NK",name:"Spirit Airlines"},
  {value:"UL",name:"SriLankan"},
  {value:"EZ",name:"Sun Air Scandina"},
  {value:"SY",name:"Sun Country"},
  {value:"2U",name:"Sun d'Or Airline"},
  {value:"XQ",name:"SunExpress"},
  {value:"WG",name:"Sunwing"},
  {value:"PY",name:"Surinam Airways"},
  {value:"LX",name:"Swiss"},
  {value:"RB",name:"Syrian Arab Air"},
  {value:"TA",name:"Taca Intl"},
  {value:"VR",name:"TACV Cabo Verde"},
  {value:"PZ",name:"TAM del Mercosur"},
  {value:"JJ",name:"TAM Linhas Aerea"},
  {value:"EQ",name:"TAME Airlines"},
  {value:"RO",name:"Tarom-Romanian"},
  {value:"TG",name:"Thai Intl"},
  {value:"MT",name:"Thomas Cook Air"},
  {value:"HQ",name:"Thomas Cook BE"},
  {value:"3P",name:"Tiara Air Auba"},
  {value:"DG",name:"Tigerair"},
  {value:"TJ",name:"Tradewind"},
  {value:"Q8",name:"Trans Air Congo"},
  {value:"AX",name:"Trans States Air"},
  {value:"UN",name:"Transaero"},
  {value:"OR",name:"Tui Airlines"},
  {value:"TU",name:"Tunis Air"},
  {value:"UG",name:"Tunisair Express"},
  {value:"TK",name:"Turkish Airlines"},
  {value:"T7",name:"Twin Jet"},
  {value:"PS",name:"Ukraine Intl"},
  {value:"B7",name:"Uni Airways"},
  {value:"UA",name:"United"},
  {value:"U6",name:"Ural Airlines"},
  {value:"US",name:"US Airways"},
  {value:"QU",name:"Utair Ukraine"},
  {value:"HY",name:"Uzbekistan"},
  {value:"VN",name:"Vietnam Air"},
  {value:"NN",name:"VIM Airlines"},
  {value:"VX",name:"Virgin America"},
  {value:"VS",name:"Virgin Atlantic"},
  {value:"VA",name:"Virgin AU Intl"},
  {value:"UK",name:"Vistara"},
  {value:"VG",name:"VLM Airlines"},
  {value:"V7",name:"Volotea"},
  {value:"G3",name:"VRG Airlines"},
  {value:"VY",name:"Vueling Airlines"},
  {value:"4W",name:"Warbelows Air"},
  {value:"WS",name:"WestJet"},
  {value:"WF",name:"Wideroes Flyvese"},
  {value:"WM",name:"Windward Island"},
  {value:"8V",name:"Wright Air Svc"},
  {value:"MF",name:"Xiamen Airlines"},
  {value:"SE",name:"XL Airways"},
  {value:"R3",name:"Yakutia"},
  {value:"YC",name:"Yamal Airlines"},
  {value:"YE",name:"YanAir"},
  {value:"IY",name:"Yemenia Yemen"},
];

exports.classOptions = [
  {value:"Y",name:"Economy/Coach"},
  {value:"S",name:"Premium Economy"},
  {value:"C",name:"Business"},
  {value:"J",name:"Premium Business"},
  {value:"F",name:"First"},
  {value:"P",name:"Premium First"},
];

exports.vehicleOptions = [
  {value:"*", name:"Any"},
  {value:"ECAR", name:"Economy"},
  {value:"CCAR", name:"Compact"},
  {value:"SCAR", name:"Standard - Car (Sedan)"},
  {value:"ICAR", name:"Mid-size - Car (Sedan)"},
  {value:"FCAR", name:"Full-size - Car (Sedan)"},
  {value:"PCAR", name:"Premium - Car (Sedan)"},
  {value:"LCAR", name:"Luxury - Car (Sedan)"},
  {value:"STAR", name:"Convertible"},
  {value:"IFAR", name:"Mid-size - SUV"},
  {value:"SFAR", name:"Standard - SUV"},
  {value:"FFAR", name:"Full-size - SUV"},
  {value:"FVAR", name:"Van"},
]

exports.rentalAgencyOptions = [
  {value: "*", name:"No Preference"},
  {value: "ZI", name:"Avis"},
  {value: "AL", name:"Alamo"},
  {value: "ZD", name:"Budget"},
  {value: "ZE", name:"Hertz"},
  {value: "ZL", name:"National"},
  {value: "ET", name:"Enterprise"},
  {value: "ZT", name:"Thrifty"},
  {value: "ZR", name:"Dollar"},
  {value: "SX", name:"Sixt"},
  {value: "AD", name:"Advantage"},
  {value: "FX", name:"Fox Rent A Car"},
  {value: "EZ", name:"E-Z Rent-A-Car"}
]
