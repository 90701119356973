import Air from "../air/Air";
import Hotel from "../hotel/Hotel";
import Auto from "../auto/Auto";
import {useNavigate, useParams} from "react-router-dom";
import ResponseForm from "./ResponseForm";
import {useGetRequest} from "../../utils/hooks/requestHooks";
import {useEffect, useState} from "react";
import {setSess} from "../../utils/dataFetching";
import {Alert} from "@mui/material";


function Response () {
  const { requestId, requestChallenge } = useParams();
  const navigate = useNavigate();

  const {data: requestData} = useGetRequest(requestId, requestChallenge);
  const [loaded, setLoaded] = useState(false);
  const [success, setSuccess] = useState(false);
  
  const onSubmitSuccess = () => {
    setSuccess(true);
  }
  
  const handleClose = (e) => {
    sessionStorage.clear();
    navigate('/');
  }

  useEffect(() => {
    if (requestData?.request?.data && !loaded) {
      const {
        flights,
        flightsSelectedTitle,
        hotel,
        room,
        roomSelectedTitle,
        vehicle,
        vehicleSelectedTitle
      } = JSON.parse(requestData.request.data);
      const grandTotal = Math.round(Number(flightsSelectedTitle[flightsSelectedTitle.length - 1])
        + Number(roomSelectedTitle[roomSelectedTitle.length - 2])
        + Number(vehicleSelectedTitle[vehicleSelectedTitle.length - 1]));

      setSess('flights', flights);
      setSess('flightsSelectedTitle', flightsSelectedTitle);
      setSess('hotel', hotel);
      setSess('room', room);
      setSess('roomSelectedTitle', roomSelectedTitle);
      setSess('vehicle', vehicle);
      setSess('vehicleSelectedTitle', vehicleSelectedTitle);
      setSess('grandTotal', grandTotal);
      setLoaded(true);
    }
  }, [requestData, loaded]);


  return (success ? <Alert onClose={handleClose} severity="success">
          Thank you for reviewing this travel itinerary! Your response has been recorded and your traveler has been notified via email.
        </Alert> : <>
    {requestData && loaded ?
      <>
        <Air />
        <Hotel />
        <Auto />
        <ResponseForm onSuccess={onSubmitSuccess} />
      </>
      : 'Request loading, please wait...'}
  </>);
}

export default Response;
