import axios from "axios";

const getRequest = async (key, obj) => {
  const [id, challenge] = [key.queryKey[1], key.queryKey[2]];
  const { data } = await axios.get(
    `${process.env.REACT_APP_API}/request/${id}/${challenge}`
  );
  return data;
};

const newRequest = async (body) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API}/request`,
    body
  );
  return data;
};

const updateRequest = async (body) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API}/request/${body.requestId}/${body.requestChallenge}`,
    body
  );
  return data;
};

export {
  getRequest,
  newRequest,
  updateRequest
}
