import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import {useState} from "react";
import Selected from "./Selected";
import {getSess, setSess} from "../../utils/dataFetching";

function Hotel () {
  const [showSearchResults, setShowSearchResults] = useState(getSess('showHotelResults'));
  const [searchResults, setSearchResults] = useState(getSess('hotelResults'));
  const [selectedItem, setSelectedItem] = useState(getSess('room'));

  if (selectedItem === 'skipped') {
    return;
  }

  const handleSearchSubmit = (results) => {
    setSearchResults(results);
    setShowSearchResults((results && true) || false);
    setSess('hotelResults', results);
    setSess('showHotelResults', (results && true) || false);
  };

  const handleItemSelection = (item) => {
    setSelectedItem(item);
    setSess('room', item);
  }

  return <>
    {!selectedItem && !searchResults && <SearchForm onSearchSubmit={handleSearchSubmit} />}
    <Selected item={selectedItem} handleSelection={handleItemSelection} />
    {!selectedItem && showSearchResults && <SearchResults handleItemSelection={handleItemSelection}
                                                                                       selectedItem={selectedItem}
                                                                                      handleSearchSubmit={handleSearchSubmit}
                                                                                      results={searchResults} />}
  </>
}

export default Hotel;
