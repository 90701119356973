import Info from "./Info";
import AgencyLogo from "../general/Logo";
import { useNavigate } from 'react-router-dom';
import {vehicleOptions} from "../../utils/constants";
import {setSess} from "../../utils/dataFetching";

function Selected ({ item, handleSelection }) {
  const parentKey = 'auto';
  const itemKey = 'vehicle';
  const navigate = useNavigate();
  const selectedTitle = itemKey+'SelectedTitle';
  
  const confirm = (e) => {
    e.stopPropagation();
    navigate('/next');
    setSess(selectedTitle,vehicleSummary);
  }

  const change = (e) => {
    e.stopPropagation();
    setSess(selectedTitle,false);
    handleSelection(false);
     navigate('/auto');
 }
  
  if (!item) {
    return;
  }

  const {
    Vendor, 
    VehRentalRate,
    PickUpLocation,
    ReturnLocation
  } = item;
  const {VehicleCharges, Vehicle} = VehRentalRate[0];
  const {Logo, Name} = Vendor;
  const {Amount} = VehicleCharges.VehicleCharge.find(vc => vc.ChargeType === "ApproximateTotalPrice");

  const VehType = () => {
    const vt = vehicleOptions.find(v => v.value === Vehicle.VehType);
    return vt ? vt.name : Vehicle.VehType;
  }

  const vehicleSummary = [
    `${Name}, ${VehType()}, `
    +` Pickup: ${PickUpLocation?.LocationCode} ${PickUpLocation?.PickUpDate}`
    +` Return: ${ReturnLocation?.LocationCode} ${ReturnLocation?.ReturnDate}`,
    Amount
  ];

  return (
    <div className="auto selected-item container justify-content-between align-items-center flex-row flex-wrap">
      <div className="container bg-success text-light rounded-3 p-2 text-center">
        Selected {itemKey}: <b>{vehicleSummary[0]}:</b> ${vehicleSummary[1]}
      </div>
      <div className="col-3 d-flex flex-nowrap info">
          {!Logo ? <h4>{Name}</h4> : <AgencyLogo src={Logo} k={parentKey} />}
      </div>
      <div className="col-9">
        <Info selected={true} item={item} handleSelection={handleSelection} />
      </div>
      <div className="col-12 justify-content-end d-flex mt-3">
        <button onClick={change} className="btn btn-info">Change</button>
        <button onClick={confirm} className="btn btn-success ms-4">Select</button>
      </div>
    </div>
  );
}

export default Selected;
