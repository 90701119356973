import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import {useState} from "react";
import Selected from "./Selected";
import {getSess, setSess, getLocal} from "../../utils/dataFetching";

function Auto () {
  const [showSearchResults, setShowSearchResults] = useState(getSess('showAutoResults'));
  const [searchResults, setSearchResults] = useState(getSess('autoResults'));
  const [selectedItem, setSelectedItem] = useState(getSess('vehicle'));

  if (selectedItem === 'skipped') {
    return;
  }

  const autoSearchFormData = getLocal('autoSearchFormData');
  
  const handleSearchSubmit = (results) => {
    setSearchResults(results);
    setShowSearchResults((results && true) || false);
    setSess('autoResults', results);
    setSess('showAutoResults', (results && true) || false);
  };

  const handleItemSelection = (item) => {
    if (item) {
      item.PickUpLocation.PickUpDate = autoSearchFormData.pickupDate;
      item.ReturnLocation.ReturnDate = autoSearchFormData.dropoffDate;
    }
    
    setSelectedItem(item);
    setSess('vehicle', item);
  }

  return <>
    {!selectedItem && !searchResults && <SearchForm onSearchSubmit={handleSearchSubmit} />}
    <Selected item={selectedItem} handleSelection={handleItemSelection} />
    {!selectedItem && showSearchResults && <SearchResults handleItemSelection={handleItemSelection}
                                                                                       selectedItem={selectedItem}
                                                                                      handleSearchSubmit={handleSearchSubmit}
                                                                                      results={searchResults} />}
  </>
}

export default Auto;
