import axios from "axios";

const getFlightAvailability  = async (formData)=> {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API}/air/search`, formData);

    const data = await response.data;
    return data.results;
  } catch (error) {
    throw new Error('An error occurred while searching for flights');
  }
}

export { getFlightAvailability }
