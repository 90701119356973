import React, { useState } from 'react';
import {getSess} from "../../utils/dataFetching";
import '../stylesheets/Info.css';
import {formatDuration, formatTimeWithZone} from "../../utils/helpers";
import {airlineOptions} from "../../utils/constants";

const Info = ({ item, handleSelection, parentIdx, legDescriptions }) => {
  const searchResults = getSess('airResults');
  const selectedItems = getSess('flights');

  const { legDescs, scheduleDescs } = searchResults;

  const {
    pricingInformation,
    pricingSource
  } = item;
  
  const legs = item.legs.map((l,li) => {
    let leg = l;
    if (l.ref) {
      leg = legDescs.find((ld) => l.ref === ld.id);

      if (leg.schedules[0].ref) {
        leg.schedules = leg.schedules.map((s) => scheduleDescs.find((sd) => s.ref === sd.id));
        leg.description = legDescriptions[li];
      }
    }

    return leg;
  });

  const selected = selectedItems && selectedItems?.find(si => si.id === item.id);

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = (e) => {
    e.stopPropagation();
    setShowDetails(!showDetails);
  };
  
  const select = (e) => {
    e.stopPropagation();
    handleSelection({...item,legs}, parentIdx);
  }

  const listLegs = () => {
    const { fare } = pricingInformation[0];

    return (
      <>
        <h4>${fare.totalFare.totalPrice} {pricingInformation.length > 1 ? "*Multiple Found" : ''}</h4>
        <ul className="legs ps-0 ms-0 d-flex flex-wrap">

          {legs.map((leg,i) => {
            const { elapsedTime, schedules, description } = leg;

            return (
              <li className="leg col-6 col-xxl-4" key={i}>
                <ul className="p-3 m-2 text-bg-secondary rounded-3">
                  <li><h5><b>Trip Duration:</b> {formatDuration(elapsedTime)}</h5></li>
                  <li><h5><b>Date:</b> {description?.departureDate} </h5></li>
                  {listSchedules(schedules, description)}
                </ul>
              </li>
            );

          })}
        </ul>
      </>
    );
  }

  const listSchedules = (scheds, desc) => {
    return scheds.map((sched,i) => {
      const {
        arrival,
        carrier,
        departure,
        elapsedTime,
        totalMilesFlown,
      } = sched;
      const carrierName = carrier.marketing !== carrier.operating ?
                            `${airlineOptions.find(a => a.value === carrier.operating).name} as ${airlineOptions.find(a => a.value === carrier.marketing).name}`
                            : airlineOptions.find(a => a.value === carrier.operating).name;
      return (
        <li className="schedule" key={i}>
          <h6><b>Flight #:</b> {carrier.marketingFlightNumber} ({carrierName}) <b>Duration:</b> {formatDuration(elapsedTime)}</h6>
          <h6><b>Distance:</b> {totalMilesFlown.toLocaleString()} mi</h6>
          <h6><b>Departs:</b> {departure.airport} ({departure.city}, {departure.state}): {formatTimeWithZone(desc.departureDate + 'T' + departure.time)}</h6>
          <h6><b>Arrives:</b> {arrival.airport} ({arrival.city}, {arrival.state}): {formatTimeWithZone(desc.departureDate + 'T' + arrival.time)}</h6>
        </li>
      )
    });

  }

  return (
    <div onClick={select}
         className={"item-info col-12" + (selected ? ' active':' btn btn-light')}>
      {listLegs()}
      <div className="d-none justify-content-between">
        <p className="toggle-details" onClick={toggleDetails}>More...</p>
      </div>

      {showDetails && (
        <>
          <p>{pricingSource}</p>
        </>
      )}
    </div>
  );
};

export default Info;
