import {useMutation, useQuery, useQueryClient} from "react-query";
import {getRequest, newRequest, updateRequest} from "../fetch/requestFetching";

function useGetRequest(id,challenge) {
  const { status, data, error, isError, isFetching, isLoading } = useQuery(
    ["request", id, challenge],
    getRequest
  );
  return { status, data, error, isError, isFetching, isLoading };
}

function useCreateRequest() {
  const queryClient = useQueryClient();

  return useMutation(newRequest, {
    onSuccess: (data) => {
      queryClient.refetchQueries(["request"]);
    },
  });
}

function useUpdateRequest() {
  const queryClient = useQueryClient();

  return useMutation(updateRequest, {
    onSuccess: (data) => {
      queryClient.refetchQueries(["request"]);
    },
  });
}

export {
  useGetRequest,
  useCreateRequest,
  useUpdateRequest
}
