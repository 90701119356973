import { useQuery } from "react-query";
import {getHotelRooms} from "../fetch/hotelFetching";

function useGetRooms(hotelInfo) {
  const {HotelCode, CodeContext} = hotelInfo;

  const { status, data, error, isError, isFetching, isLoading } = useQuery(
    ["hotel", HotelCode, CodeContext],
    getHotelRooms
  );
  return { status, data, error, isError, isFetching, isLoading };
}

export {
  useGetRooms
}
