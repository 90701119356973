import Air from "../air/Air";
import Hotel from "../hotel/Hotel";
import Auto from "../auto/Auto";
import RequestForm from "./RequestForm";


function Review () {
  return <>
    <RequestForm />
    <Air />
    <Hotel />
    <Auto />
    <div className="col-12">
      <p style={{fontSize:"0.9rem"}}>
        TERMS: All pricing is based on current availability. Final costs are based on final hotel, car and fees which are incurred by vendors directly upon check out/return. Variations and other options may become available after pricing inquiry. The Tangerine Travel pricing app is best used as an estimate/guide for pre-approvals. Tangerine fees are not included. Tangerine will not be held liable if fares change, hotel fees are incurred or if availability changes.
      </p>
    </div>
  </>;
}

export default Review;
