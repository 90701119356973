import Info from "./Info";
import {setSess} from "../../utils/dataFetching";
import { useNavigate } from 'react-router-dom';

function Selected ({ items, handleSelection }) {
  const itemKey = 'flights';
  const selectedTitle = itemKey+'SelectedTitle';
  const navigate = useNavigate();

  if (!items || !items.length) {
    return;
  }

  const item = items[0]; // Currently selected flights is an array, may not be necessary

  const {totalPrice} = item.pricingInformation[0].fare.totalFare;

  let flightSummary = [];
  
  item.legs.forEach(l => {
    const {
      description:{
        departureLocation,
        departureDate,
        arrivalLocation
      },
      schedules:[{
        carrier: {
          operating,
          operatingFlightNumber
        }
      }]
    } = l;
    let fs = '';

    fs += `Flight (${operating}) #${operatingFlightNumber}: `;
    fs += `${departureLocation} - ${arrivalLocation}`;
    fs += ` on ${departureDate}`;
    flightSummary.push(fs);
    return;
  });
  
  flightSummary = flightSummary.join(', ') + ': $'+totalPrice;
  flightSummary = flightSummary.split('$');

  const confirm = (e) => {
    e.stopPropagation();
    setSess(selectedTitle,flightSummary);
    navigate('/next');
  }

  const change = (e) => {
    e.stopPropagation();
    setSess(selectedTitle,false);
    handleSelection(false);
    navigate('/air');
  }

  return (
    <div className="selected-item container justify-content-between align-items-center flex-row flex-wrap">
      <div className="container bg-success text-light rounded-3 p-2 text-center">
        Selected {itemKey}: <b>{flightSummary[0]}</b> ${flightSummary[1]}
      </div>
      <div className="col-12">
        {items.map((itm,i) =>
          <Info item={itm} key={i} handleSelection={handleSelection} />
        )}
      </div>
      <div className="col-12 justify-content-end d-flex mt-3">
        <button onClick={change} className="btn btn-info">Change</button>
        <button onClick={confirm} className="btn btn-success ms-4">Select</button>
      </div>
    </div>
  );
}

export default Selected;
