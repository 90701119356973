import React, {useCallback, useEffect, useState} from 'react';
import '../stylesheets/AutoComplete.css';
import {getAutoGeoCompleteSuggestions} from "../../utils/fetch/autoCompleteFetching";

const AutoGeoComplete = ({ name,
                        value,
                        type,
                        handleChange,
                        inputSuggestValue,
                        focusedInput,
                        setFocusedInput }) => {

  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noSuggestions, setNoSuggestions] = useState(false);

  const fetchSuggestions = useCallback(async () => {
    if (!/\(|\)/.test(inputSuggestValue)) {
      setLoading(true);
      try {
        const response = await getAutoGeoCompleteSuggestions(inputSuggestValue, type);
        setLoading(false);
        setSuggestions(response);
        setNoSuggestions(!response || response?.length === 0);
      } catch (error) {
        setLoading(false);
        setSuggestions([]);
        setNoSuggestions(true);
        console.error('Error fetching suggestions:', error);
      }
    }
  }, [inputSuggestValue, type]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputSuggestValue.length > 2) {
        fetchSuggestions();
      }
    }, 1500);
    setSuggestions([]);
    setNoSuggestions(false);

    return () => clearTimeout(delayDebounceFn);
  }, [inputSuggestValue, fetchSuggestions]);

  const handleSuggestionSelect = (e, name, value) => {
    e.preventDefault();
    e.stopPropagation();
    handleChange({target:{name, value}});
    setFocusedInput('');
  }

  return (
    <>
      {name === focusedInput && !/\(|\)/.test(value) ?
        <>{loading && <div className="loading-indicator">Loading...</div>}
          {noSuggestions && <div className="no-suggestions">No suggestions</div>}
          {!loading && !noSuggestions && (
            <ul className="suggestions-list">
              {suggestions && suggestions.length ? suggestions.map((suggestion) => (
                <li onClick={(e) => handleSuggestionSelect(e, name, type === 'AIR' ? `${suggestion.id} (${suggestion.name})` : suggestion.name )} key={suggestion.id}>
                  {type === 'AIR' ? <><em>{suggestion.name}</em> ({suggestion.id})</> : suggestion.name }
                </li>
              )) : value.length < 3 ?
                <li>3 character minimum</li> : <li>Loading...</li>}
            </ul>
          )}</> : null}
    </>
  );
};

export default AutoGeoComplete;
