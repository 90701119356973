import React, {useEffect, useState} from 'react';
import {getLocal, setLocal, getSess, setSess} from "../../utils/dataFetching";
import {CarRental, Place} from "@mui/icons-material";
import {Autocomplete, FormControl, TextField} from "@mui/material";
import {rentalAgencyOptions, vehicleOptions} from "../../utils/constants";
import {getVehicleAvailability} from "../../utils/fetch/autoFetching";
import {formattedDate} from "../../utils/helpers";
import {useNavigate} from "react-router-dom";

import '../stylesheets/SearchForm.css';
import AutoGeoComplete from "../general/AutoGeoComplete";

const SearchForm = ({onSearchSubmit}) => {
  const parentKey = 'auto';
  const navigate = useNavigate();
  const savedAirFormData = getLocal('airSearchFormData');
  const selectedFlights = getSess('flights');

  const [formData, setFormData] = useState(getLocal(parentKey+'SearchFormData') || {
    pickup: savedAirFormData ? savedAirFormData.destination[0].split(' ')[0] : '',
    dropoff: savedAirFormData ? savedAirFormData.destination[0].split(' ')[0] : '',
    pickupDate: savedAirFormData ? savedAirFormData.departDate[0] : '',
    pickupTime: selectedFlights !== 'skipped' && selectedFlights?.length ? selectedFlights[0].legs[0].schedules[selectedFlights[0].legs[0].schedules.length - 1].arrival.time.split('-')[0] : "08:00",
    dropoffDate: savedAirFormData ? savedAirFormData.returnDate : '',
    dropoffTime: selectedFlights !== 'skipped' && selectedFlights?.length ? selectedFlights[0].legs[selectedFlights[0].legs.length - 1].schedules[0].departure.time.split('-')[0] : "08:00",
    vehicle: [vehicleOptions[0]],
    rentalAgency: [rentalAgencyOptions[0]],
    sortBy: 'Price',
    rateMin: 0,
    rateMax: 9999,
    ratingMin: 3,
    ratingMax: 5
  });

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const [focusedInput, setFocusedInput] = useState('');
  const [inputSuggestValue, setInputSuggestValue] = useState('');

  const handleInputFocus = (e) => {
    setFocusedInput(e.target.name);
    e.stopPropagation();

    if (e.target.value?.length > 2 && e.target.value !== inputSuggestValue) {
      setInputSuggestValue(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submitted) {
      return;
    }
    setSubmitted(true);
    setError('');

    try {
      const searchResults = await getVehicleAvailability(formData);

      onSearchSubmit(searchResults);
    } catch (error) {

      setError(error.message);
    }
    setSubmitted(false);
  };

  const handleToggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  // Save form data to localStorage on change
  useEffect(() => {
    setLocal(parentKey+'SearchFormData', formData);
  }, [formData]);

  const handleRentalAgencyChange = (e, nv) => {
    if (nv && typeof nv.length != 'undefined' && !nv.length) {
      nv.push(rentalAgencyOptions[0]);
    }
    return handleChange({target:{name:'rentalAgency',value:nv}});
  }

  const handleVehicleChange = (e, nv) => {
    if (nv && typeof nv.length != 'undefined' && !nv.length) {
      nv.push(vehicleOptions[0]);
    }
    return handleChange({target:{name:'vehicle',value:nv}});
  }

  const handleChange = (e) => {
    const v = e.target.value;
    const val = !isNaN(v) && v ? Number(v) : v;

    if (val && val.length > 1 && val[0].value === '*') {
      val.shift();
    }

    if (/pickup|dropoff/.test(e.target.name)) {
      if (e.currentTarget) {
        setInputSuggestValue(e.target.value);
      }
    }

    setFormData({
      ...formData,
      [e.target.name]: val
    });
  };

  const handleSkip = (e) => {
    e.stopPropagation();
    setSess('vehicle','skipped');
    navigate('/next');
  }

  return (
    <div className="container search-form">
      <h2><CarRental fontSize="large" /> Search <CarRental fontSize="large" /></h2>
      <button onClick={handleSkip} type="submit" className="m-3 btn btn-info skip">
        Skip
      </button>
      <form onSubmit={handleSubmit} className="row">
        <div className="form-group col-6">
          <div className="input-group">
            <span className="input-group-text"><Place fontSize="large" /></span>
            <input
              type="text"
              className="form-control"
              id="pickup"
              name="pickup"
              value={formData.pickup.toUpperCase()}
              onChange={handleChange}
              placeholder="Enter Pickup location"
              onFocus={handleInputFocus}
              autoComplete="off"
              required
            />
            <AutoGeoComplete name="pickup"
                             value={formData.pickup}
                             type={'CITY'}
                             handleChange={handleChange}
                             inputSuggestValue={inputSuggestValue}
                             focusedInput={focusedInput}
                             setFocusedInput={setFocusedInput} />
          </div>
        </div>
        <div className="form-group col-6">
          <div className="input-group">
            <span className="input-group-text"><Place fontSize="large" /></span>
            <input
              type="text"
              className="form-control"
              id="dropoff"
              name="dropoff"
              value={formData.dropoff.toUpperCase()}
              onChange={handleChange}
              placeholder="Enter Dropoff location"
              onFocus={handleInputFocus}
              autoComplete="off"
              required
            />
            <AutoGeoComplete name="dropoff"
                             value={formData.dropoff}
                             type={'CITY'}
                             handleChange={handleChange}
                             inputSuggestValue={inputSuggestValue}
                             focusedInput={focusedInput}
                             setFocusedInput={setFocusedInput} />
          </div>
        </div>
        <div className="form-group col-6">
          <label htmlFor="pickupDate" className="col-4">Pickup Date</label>
          <input
            type="date"
            className="form-control"
            id="pickupDate"
            name="pickupDate"
            value={formData.pickupDate}
            onChange={handleChange}
            min={formattedDate()}
            required
          />
          <input
            type="time"
            className="form-control"
            id="pickupTime"
            name="pickupTime"
            value={formData.pickupTime}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="dropoffDate" className="col-4">Dropoff Date</label>
          <input
            type="date"
            className="form-control"
            id="dropoffDate"
            name="dropoffDate"
            value={formData.dropoffDate}
            onChange={handleChange}
            min={formData.pickupDate}
            required
          />
          <input
            type="time"
            className="form-control"
            id="dropoffTime"
            name="dropoffTime"
            value={formData.dropoffTime}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group col-6">
          <FormControl fullWidth>
            <Autocomplete
              multiple
              limitTags={2}
              id="vehicle"
              options={vehicleOptions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(o,v) => {
                return o.value === v.value
              }}
              value={formData.vehicle}
              onChange={handleVehicleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Multiple values"
                  placeholder="Vehicle Preference"
                />
              )}
            />

          </FormControl>

        </div>
        <div className="form-group col-6">
          <FormControl fullWidth>
            <Autocomplete
              multiple
              limitTags={2}
              id="rentalAgency"
              options={rentalAgencyOptions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(o,v) => {
                return o.value === v.value
              }}
              value={formData.rentalAgency}
              onChange={handleRentalAgencyChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Multiple values"
                  placeholder="Rental Agency Preference"
                />
              )}
            />
          </FormControl>
        </div>

        <div className="form-group col-12 text-right">
          <button type="button" className={"me-5 btn btn-"+(showAdvanced?"warning":"dark")} onClick={handleToggleAdvanced}>
            Advanced
          </button>
          <button disabled={submitted ? true : false} 
            type="submit" 
            className={`ms-5 btn btn-${submitted ? 'secondary':'primary'}`}>
            {submitted ? 'Loading...' : 'Search'}
          </button>
        </div>

        {showAdvanced && (
          <>
            <div className="form-group col-4">
              <label htmlFor="sortBy">Sort By</label>
              <select
                className="form-control"
                id="sortBy"
                name="sortBy"
                value={formData.sortBy}
                onChange={handleChange}
              >
                <option value="Price">Price</option>
                <option value="Vendor">Vendor</option>
              </select>
            </div>
            <div className="form-group col-4">
              <label htmlFor="rateMin">Price Min</label>
              <input
                type="number"
                className="form-control"
                id="rateMin"
                name="rateMin"
                value={formData.rateMin}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-4">
              <label htmlFor="rateMax">Price Max</label>
              <input
                type="number"
                className="form-control"
                id="rateMax"
                name="rateMax"
                value={formData.rateMax}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-4">
              <label htmlFor="ratingMin">Rating Min</label>
              <input
                type="number"
                className="form-control"
                id="ratingMin"
                name="ratingMin"
                min={0}
                max={5}
                step={0.5}
                value={formData.ratingMin}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-4">
              <label htmlFor="ratingMax">Rating Max</label>
              <input
                type="number"
                className="form-control"
                id="ratingMax"
                name="ratingMax"
                min={0}
                max={5}
                step={0.5}
                value={formData.ratingMax}
                onChange={handleChange}
              />
            </div>
          </>
        )}

      </form>
      {error && (<div className="form-group error">{error}</div> )}
    </div>
  );
};

export default SearchForm;
