import React, {useState} from 'react';
import Result from './SearchResult';
import "../stylesheets/SearchResults.css";
import {setSess} from "../../utils/dataFetching";

const SearchResults = ({ results, 
                        handleItemSelection, 
                        handleSearchSubmit,
                        selectedItem }) => {
  const parentKey = 'auto';
  const [selectedParent, setSelectedParent] = useState(false);
  const handleParentSelection = (p) => {
    setSelectedParent(p);
    setSess(parentKey,p);
  }

  let res = {};

  results?.VehAvailInfos?.VehAvailInfo.map(v => {
    res[v.Vendor.Code] = res[v.Vendor.Code] || [];
    return res[v.Vendor.Code].push(v);
  });

  Object.keys(res).map((ven,i) => {
    const vendorResults = res[ven];
    let min = 0,max = 0;

    vendorResults.map(v => {
      const charge = v.VehRentalRate[0].VehicleCharges.VehicleCharge.find(c => c.ChargeType === "ApproximateTotalPrice");
      if (charge) {
        const { Amount } = charge;
        min = min ? Math.min(min,Number(Amount)) : Number(Amount);
        max = max ? Math.max(max,Number(Amount)) : Number(Amount);
      }
      return v;
    });

    vendorResults.min = min;
    vendorResults.max = max;

    return vendorResults;
  })

  const change = (e) => {
    e.stopPropagation();
    handleSearchSubmit(false);
  }

  return (
    <div className="container">
      <h2>
        {res ? 
        <>
          Results ({Object.keys(res).length})
        </> : <>
          No results.  Modify search and try again: 
        </>}
        <button onClick={change} className="btn btn-info mx-4">Change</button>      
      </h2>
      <div className="results d-flex flex-wrap">
        {res ? Object.keys(res).map((v,i) => (
          <Result key={i} parent={res[v]}
                  selectedItem={selectedItem}
                  selected={res[v][0].Vendor.Code === selectedParent?.Vendor?.Code}
                  handleItemSelection={handleItemSelection}
                  handleParentSelection={handleParentSelection} />
        )) : null}</div>
    </div>
  );
};

export default SearchResults;
