import React, { useState } from 'react';
import '../stylesheets/SearchResult.css';
import Available from './Available';
import Modal from "../general/Modal";
import ParentLogo from "../general/Logo";

const SearchResult = ({ parent,
                        handleItemSelection,
                        handleParentSelection,
                        selected,
                        selectedItem }) => {
  const parentKey = 'auto';
  const {
    Name,
    Logo,
  } = parent[0].Vendor;

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
    handleParentSelection(parent);
  };

  const closeModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
  };

  return (
    <div className={"col-4 col-xl-3 result" + (selected ? ' active' : '')} onClick={openModal}>
      <div className="m-1 btn btn-light d-flex col-12 align-items-center">
        <div className="col-5">
          <ParentLogo src={Logo} k={parentKey} />
        </div>
        <div className="details col-7">
          {!Logo ? <h3>{Name}</h3> : null }
          <p>{parent.min === parent.max ?
            <><b>Rate:</b> ${parent.min}</> :
            <><b>Rate Range:</b> ${parent.min} - ${parent.max}</>
          }
          </p>
        </div>
      </div>

      <Modal isOpen={showModal} onClose={closeModal}>
        <Available parent={parent}
                   selectedItem={selectedItem}
                   closeModal={closeModal}
                   handleSelection={handleItemSelection} />
      </Modal>
    </div>
  );
};

export default SearchResult;
