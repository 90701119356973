import axios from "axios";

const getCompany = async (companyCode) => {
  let res;

  try {
    res = await axios.get(
      `${process.env.REACT_APP_API}/company/${companyCode}`
    );

    if (!res.data) {
      throw new Error(res);
    }

    return res.data;
  } catch (error) {

    throw new Error(error.response?.data ? error.response.data : error.code);
  }

};

export {
  getCompany
}
