import Info from "./Info";
import {getSess, setSess} from "../../utils/dataFetching";
import Logo from "../general/Logo";
import { useNavigate } from 'react-router-dom';
import {formatSpanInDays} from "../../utils/helpers";

function Selected ({ item, handleSelection }) {
  const parentKey = 'hotel';
  const itemKey = 'room';
  const navigate = useNavigate();
  const parent = getSess(parentKey);

  const selectedTitle = itemKey+'SelectedTitle';

  const confirm = (e) => {
    e.stopPropagation();
    navigate('/next');
    setSess(selectedTitle,roomSummary);
  }

  const change = (e) => {
    e.stopPropagation();
    setSess(selectedTitle,false);
    handleSelection(false);
    navigate('/hotel');
  }

  if (!item || !parent) {
    return;
  }

  const {Logo: parentLogo, HotelName: parentName} = parent?.HotelInfo;
  const {
    RoomType,
    RatePlans: {
      RatePlan: [
        {
          ConvertedRateInfo: {
            AmountAfterTax,
            AverageNightlyRate,
            StartDate,
            EndDate,
          },
          RatePlanName
        }
      ]
    }
  } = item;

  const roomSummary = [
    `${parentName}, ${(RoomType || RatePlanName)}, ${formatSpanInDays(StartDate, EndDate)}`,
    AmountAfterTax,
    AverageNightlyRate
  ];

  return <div className={`selected-item container justify-content-between align-items-center flex-row flex-wrap`}>
      <div className="container bg-success text-light rounded-3 p-2 text-center">
        Selected {itemKey}: <b>{roomSummary[0]}:</b> ${roomSummary[1]} (~${roomSummary[2]} nightly)
      </div>
    <div className="col-6 d-flex flex-nowrap info">
        <Logo src={parentLogo} k={parentKey} />
        <h4>{parentName}</h4>
      </div>
      <div className="col-6">
        <Info selected={true} item={item} handleSelection={handleSelection} />
      </div>
      <div className="col-12 justify-content-end d-flex mt-3">
        <button onClick={change} className="btn btn-info">Change</button>
        <button onClick={confirm} className="btn btn-success ms-4">Select</button>
      </div>
  </div>
}

export default Selected;
