import {useNavigate} from "react-router-dom";
import React from "react";
import {Alert} from "@mui/material";


function Success () {
  const navigate = useNavigate();

  const handleClose = (e) => {
    navigate('/next');
  }

  return <Alert onClose={handleClose} severity="success">
      Thank you for submitting your travel itinerary! It is now pending review by your Travel Approver.  Please check your email for more details.
    </Alert>;
}

export default Success;
