import React, {useEffect, useState} from 'react';
import {getLocal, getSess, setLocal, setSess} from "../../utils/dataFetching";

import '../stylesheets/SearchForm.css';
import {
  useCreateRequest,
  //useGetRequest
} from "../../utils/hooks/requestHooks";
import {useNavigate} from "react-router-dom";

const RequestForm = () => {
  const parentKey = 'request';
  const navigate = useNavigate();

  const flights = getSess('flights');
  const flightsSelectedTitle = getSess('flightsSelectedTitle');
  const hotel = getSess('hotel');
  const hotelSearchFormData = getLocal('hotelSearchFormData');
  const room = getSess('room');
  const roomSelectedTitle = getSess('roomSelectedTitle');
  const vehicle = getSess('vehicle');
  const vehicleSelectedTitle = getSess('vehicleSelectedTitle');

  const grandTotal = () => {
    let gt = 0;

    if (flightsSelectedTitle && flightsSelectedTitle[flightsSelectedTitle.length - 1]) {
      gt += Number(flightsSelectedTitle[flightsSelectedTitle.length - 1]);
    }
    if (roomSelectedTitle && roomSelectedTitle[roomSelectedTitle.length - 2]) {
      gt += Number(roomSelectedTitle[roomSelectedTitle.length - 2]);
    }
    if (vehicleSelectedTitle) {
      gt += Number(vehicleSelectedTitle[vehicleSelectedTitle.length - 1]);
    }
                   
    return gt ? '$' + Math.round(gt) : gt;
  }

  const newRequestObj = useCreateRequest();
  //const request = useGetRequest();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState(getLocal(parentKey+'FormData') || {
    firstName: '',
    lastName: '',
    comments: '',
    reviewerEmail: '',
    requesterEmail: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitted || !grandTotal()) {
      return;
    }
    setSubmitted(true);
    setError('');

    try {
      let departDate = (flightsSelectedTitle + roomSelectedTitle + vehicleSelectedTitle).match(/\d{4}-\d{2}-\d{2}/);
      departDate = departDate && departDate[0];
      let destination = ((flights?.length && flights[0].legs[0].description.arrivalLocation)
                        || (room && hotelSearchFormData && hotelSearchFormData.destination)
                        || (vehicle && vehicle.PickUpLocation.LocationCode)).toUpperCase();
      let requestTitle = [departDate, destination, formData.firstName].join(', ');

      await newRequestObj.mutateAsync({
        formData,
        flights,
        flightsSelectedTitle,
        hotel,
        room,
        roomSelectedTitle,
        vehicle,
        vehicleSelectedTitle,
        requestTitle
      });

      const emailOrCompanyCode = getSess('emailOrCompanyCode');
      const company = getSess('company');

      sessionStorage.clear();

      setSess('emailOrCompanyCode',emailOrCompanyCode);
      setSess('company',company);

      navigate('/success');

    } catch (error) {
      setSubmitted(false);

      setError(error.response?.data?.message ? error.response.data.message : error.message);
    }

  };

  const handleChange = (e) => {
    if (typeof e.target.value._isValid != 'undefined' && !e.target.value._isValid) {
      return;
    }
    const v = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let n = e.target.name;
    let val = e.target.type !== 'checkbox' && !isNaN(v) && v ? Number(v) : v;

    setFormData({
      ...formData,
      [n]: val
    });
  };

  // Save form data to localStorage on change
  useEffect(() => {
    setLocal(parentKey+'FormData', formData);
  }, [formData]);

  return <div className="container search-form">
          <h2 className="normal-case">Submit Request: {grandTotal() || 'No selections made. Click CONFIRM on at least one rate result.'}</h2>

    {grandTotal() ? <form onSubmit={handleSubmit} className="d-flex flex-wrap align-items-start">
            <div className="form-group pe-0 col-6">
              <label htmlFor="firstName">First Name</label>
              <div className="input-group">
              <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group pe-0 col-6">
              <label htmlFor="lastName">Last Name</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group pe-0 col-6">
              <label htmlFor="requesterEmail">Your Email</label>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control"
                  id="requesterEmail"
                  name="requesterEmail"
                  value={formData.requesterEmail}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group pe-0 col-6">
             <label htmlFor="reviewerEmail">Reviewer Email</label>
             <div className="input-group">
                <input
                  type="email"
                  className="form-control"
                  id="reviewerEmail"
                  name="reviewerEmail"
                  value={formData.reviewerEmail}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group pe-0 col-6">
             <label htmlFor="comments">Comments</label>
              <div className="input-group">
            <textarea
              type="text"
              className="form-control"
              id="comments"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
            />
              </div>
            </div>
            <div className="form-group col-6 d-flex justify-content-end">
              <button type="button" 
                className="me-4 mt-4 btn btn-secondary"
                onClick={e => window.print()}>
                Print/Save as PDF
              </button>
              <button disabled={submitted ? true : false} 
                type="submit" 
                className={`mt-4 btn btn-${submitted ? 'secondary':'primary'}`}>
                {submitted ? 'Submitting...' : 'Submit Request'}
              </button>
            </div>

          </form> : null}
          {error && (<div className="form-group error">{error}</div>)}
        </div>
};

export default RequestForm;
