import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import {useState} from "react";
import Selected from "./Selected";
import {getSess, setSess, setLocal} from "../../utils/dataFetching";

function Air () {
  const [showSearchResults, setShowSearchResults] = useState(getSess('showAirResults'));
  const [searchResults, setSearchResults] = useState(getSess('airResults'));
  const [selectedItems, setSelectedItems] = useState(getSess('flights'));

  if (selectedItems === 'skipped') {
    return;
  }
  
  const handleSearchSubmit = (results) => {
    setSearchResults(results);
    setShowSearchResults((results && true) || false);
    setSess('airResults', results);
    setSess('showAirResults', (results && true) || false);
  };

  const handleItemSelection = (item, index) => {
    let items = !item ? false : (selectedItems ? [...selectedItems] : []);
    if (typeof items.length != 'undefined') {
      if (index > items.length - 1) {
        items.push(item);
      }
      else {
        items[index] = item;
      }
    }

    setSelectedItems(items);
    setSess('flights', items);
    if (items) {
      setLocal('autoSearchFormData',false);
      setLocal('hotelSearchFormData',false);
    }
  }

  return <>
    {(!selectedItems || !selectedItems.length) && !searchResults && <SearchForm onSearchSubmit={handleSearchSubmit} />}
    <Selected items={selectedItems} handleSelection={handleItemSelection} />
    {(!selectedItems || !selectedItems.length) && showSearchResults && <SearchResults handleItemSelection={handleItemSelection}
                                                                                      handleSearchSubmit={handleSearchSubmit}
                                                                                      results={searchResults} />}
  </>
}

export default Air;
