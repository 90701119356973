import React, {useEffect, useState} from 'react';
import {getLocal, setLocal} from "../../utils/dataFetching";

import '../stylesheets/SearchForm.css';
import {
  useUpdateRequest,
} from "../../utils/hooks/requestHooks";
import {useParams} from "react-router-dom";

const ResponseForm = ({onSuccess}) => {
  const parentKey = 'response';
  const { requestId, requestChallenge } = useParams();

  const updateRequestObj = useUpdateRequest();
  //const request = useGetRequest();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState(getLocal(parentKey+'FormData') || {
    comments: '',
    status: '',
  });

  const handleDeny = (e) => {
    formData.status = 'denied';
    setFormData(formData);
    return handleSubmit(e);
  }

  const handleApprove = (e) => {
    formData.status = 'approved';
    setFormData(formData);
    return handleSubmit(e);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitted) {
      return;
    }
    setSubmitted(true);
    setError('');

    try {
      await updateRequestObj.mutateAsync({
        requestId,
        requestChallenge,
        formData,
      });

      onSuccess();

    } catch (error) {
      setError(error.response?.data?.message ? error.response.data.message : error.message);
    }

    setSubmitted(false);
  };

  const handleChange = (e) => {
    if (typeof e.target.value._isValid != 'undefined' && !e.target.value._isValid) {
      return;
    }
    const v = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let n = e.target.name;
    let val = e.target.type !== 'checkbox' && !isNaN(v) && v ? Number(v) : v;

    setFormData({
      ...formData,
      [n]: val
    });
  };

  // Save form data to localStorage on change
  useEffect(() => {
    setLocal(parentKey+'FormData', formData);
  }, [formData]);

  return <div className="container search-form">
        <h2>Submit Response:</h2>

        <form onSubmit={handleSubmit} className="d-flex flex-wrap align-items-start container">

          <div className="form-group pe-0 col-12">
            <div className="input-group">
            <textarea
              type="text"
              className="form-control"
              id="comments"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
              placeholder="Comments to Reviewer"
            />
            </div>
          </div>
          <div className="form-group col-12 d-flex justify-content-between">
            <button onClick={handleDeny} className={"btn mt-4 btn-" + (submitted ? "secondary" : "danger")}>
              Deny
            </button>
            <button onClick={handleApprove} className={"btn mt-4 btn-" + (submitted ? "secondary" : "primary")}>
              Approve
            </button>
          </div>

        </form>
        {error && (<div className="form-group error">{error}</div>)}
      </div>
};

export default ResponseForm;
