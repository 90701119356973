import React from 'react';
import '../stylesheets/SearchResult.css';
import Available from './Available';

const SearchResult = ({ parent,
                        handleItemSelection,
                        parentIdx
                      }) => {
  const {
    groupDescription,
    itineraries,
  } = parent;
  const { legDescriptions } = groupDescription;


  let totalFares = 0;
  let faresTotal = 0;
  itineraries.map(i => i.pricingInformation.map(pi => {
    totalFares++;
    faresTotal += pi.fare.totalFare.totalPrice;
    return null
  }));

  return (
    <div className="result d-flex flex-wrap">
      <div className="col-12 d-flex flex-wrap">
        <div className="details col-12 d-flex justify-content-center">
          {legDescriptions.map((leg,i) =>
            <div className="col-3" key={i}>
              <h3>{leg.departureLocation} to {leg.arrivalLocation}</h3>
              <p>Date: {leg.departureDate}</p>
            </div>
          )}
        </div>
        <p className="col-12 p-3 mb-3 text-bg-secondary rounded-3 text-center"><b>Average:</b> ${Math.round(faresTotal/totalFares)}</p>
      </div>
      <Available parent={parent}
                 parentIdx={parentIdx}
                 handleSelection={handleItemSelection} />
    </div>
  );
};

export default SearchResult;
