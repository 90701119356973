import React, { useState } from 'react';
import {vehicleOptions} from "../../utils/constants";

import '../stylesheets/Info.css';

const Info = ({ item, selected, closeModal, handleSelection }) => {
  const {
    Vehicle,
    VehicleCharges: { VehicleCharge },
  } = item.VehRentalRate[0];

  const {
    Images: { Image },
    //SeatBeltsAndBagsInfo,
  } = Vehicle;

  const VehType = () => {
    const vt = vehicleOptions.find(v => v.value === Vehicle.VehType);
    return vt ? vt.name : Vehicle.VehType;
  }

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = (e) => {
    e.stopPropagation();
    setShowDetails(!showDetails);
  };

  const select = (e) => {
    e.stopPropagation();
    handleSelection(item);
    closeModal(e);
  }

  const getRate = (typ) => {
    const rate = VehicleCharge.find(c => c.ChargeType === typ);
    return rate ? Number(rate.Amount).toLocaleString() : 0;
  }

  if (VehType() === Vehicle.VehType) {
    return;
  }

  return (
    <div onClick={select} className={"p-1 m-0 item-info" + (selected ? ' col-12 active mt-3':' col-6')}>
      <div className="p-2 d-flex align-items-center flex-wrap justify-content-between">{Image && Image.length ?
        <div className="col-6 rate-image">
          <img alt={Vehicle.VehType} src={Image[0].Url} />
        </div> : null }
        <div className="col-6">
          <h5>{VehType()}</h5>
          <h5><b>Rate:</b> ${getRate("ApproximateTotalPrice")}</h5>
        </div>

        <div className="col-12 d-flex justify-content-between">
          <div className="col-6">
            <p className="toggle-details" onClick={toggleDetails}>More...</p>

            {showDetails && (
              <>
                <p>
                  <span><b>ApproximateTotalPrice:</b> ${getRate("ApproximateTotalPrice")}</span>
                  <br/><span><b>BaseRateTotal:</b> ${getRate("BaseRateTotal")}</span>
                  <br/><span><b>Extra Day:</b> ${getRate("ExtraDay")}</span>
                  <br/><span><b>Extra Hour:</b> ${getRate("ExtraHour")}</span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
